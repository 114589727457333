import instance from "../../config/axiosConfig";
import {
  setNews,
  isLoadingNews,
  lodingNewsDone,
  setIsLodingById,
  doneIsLodingById,
  setNewsById,
} from "../../redux/slice/newsSlice";

export const getAllNews = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(isLoadingNews());
      const response = await instance.get("news");
      const news = response.data;

      dispatch(setNews(news.reverse()));
    } catch (error) {
      console.log({ ERROR: error });
    } finally {
      dispatch(lodingNewsDone());
    }
  };
};

export const getNewsById = (newsId) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setIsLodingById());
      const respone = await instance.get(`news/${newsId}`);
      const newsById = respone.data;
      dispatch(setNewsById(newsById));
    } catch (error) {
      console.log({ ERROR: error });
    } finally {
      dispatch(doneIsLodingById());
    }
  };
};

export const deleteNewsById = (newsId) => {
  return async (dispatch, getState) => {
    try {
      const response = await instance.delete(`news/${newsId}`);
      dispatch(removeNewsById(newsId));
    } catch (error) {
      console.log({ ERROR: error });
    }
  };
};

// Acción para eliminar una noticia del estado
export const removeNewsById = (newsId) => ({
  type: "news/removeNewsById",
  payload: newsId,
});
