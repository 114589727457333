export const testimonials = [
  {
    userName: "Ing. Jorge Godoy",
    UserCategory: "Security and Technology Solutions",
    userCountry: "Panamá",
    userTestimonial:
      "“La Federación es un excelente espacio donde los integradores encontraran ayuda para la mejora de sus compañías”",
  },

  {
    userName: "Ing. Martin Espinosa",
    UserCategory: "Masters Integración y Seguridad",
    userCountry: "México",
    userTestimonial:
      "“Vale mucho la pena, en la Federación encontré integradores muy profesionales y con mucha experiencia en varias áreas.”",
  },

  {
    userName: "Ing. Jorge Ordoñez",
    UserCategory: "Grupo Teselec ",
    userCountry: "Ecuador",
    userTestimonial:
      "“Se está consolidando nuestro gremio, la Federación está congregando a los mejores de Latinoamérica.”",
  },

  {
    userName: "Mario Luna",
    UserCategory: "Eixer Solution ",
    userCountry: "Chile",
    userTestimonial:
      "“Ha sido muy grato conocer a especialistas de otros países, es una gran comunidad de debate y apoyo profesional.”",
  },
];

export const descriptions = [
  {text: "Pudes ser parte de nuestra comunidad"},
  {text: "Seguimos a las nuevas tendencias"},
  {text: "Tenemos proyectos para ti"},
  {text: "Puedes ampliar tu red de contactos"},
  {text: "Puedes aumentar tu productividad e ingresos"},
]
