import { Box, Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { onCloseSidebar } from "../../../redux/slice/sidebarSlice";

const SidebarMenu = ({ sections, onClick, path }) => {
  const dispatch = useDispatch();

  return (
    <NavTabsComponent>
      {sections?.map((item, index) => (
        <Button
          key={index}
          onClick={() => {
            dispatch(onCloseSidebar());
            onClick(item.path);
          }}
          className={item.id === path ? "navigation active" : "navigation"}
        >
          <Box className="box-icons">{item.icon}</Box>
          <p>{item.label}</p>
        </Button>
      ))}
    </NavTabsComponent>
  );
};

const NavTabsComponent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 5px;
`;

export default SidebarMenu;
