import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";

const CustomQuill = ({ value, onChange, formats, modules }) => {
  const formatsDefault = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image",
    "align",
    "size",
    "font",
  ];

  const modulesDefault = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ font: [] }],
      [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      // ["image"]
    ],
  };

  return (
    <StyledComponent>
      <ReactQuill
        value={value}
        onChange={onChange}
        placeholder="Detalle general"
        theme="snow"
        style={{ borderRadius: "10px", width: "100%" }}
        formats={formats || formatsDefault}
        modules={modules || modulesDefault}
      />
    </StyledComponent>
  );
};

const StyledComponent = styled.div`
  .ql-editor {
    width: 100%;
    min-height: 200px;
    font-size: 16px;
    line-height: 1.5;
    background-color: #f8fafc;
    z-index: 0;
  }

  .ql-toolbar.ql-snow {
    font-family: inherit;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #fff;
    border: 1px solid #e5e7eb;
    border-bottom: 1px solid #e5e7eb;
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border: 1px solid #e5e7eb;
    border-top: none;
    border-radius: 0 0 10px 10px;
  }

  .ql-editor-haserror .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border: 1px solid #f56565;
    border-radius: 0 0 10px 10px;
  }
`;

export default CustomQuill;
