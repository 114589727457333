import React from "react";
import HeaderCard from "../../common/headerCard/HeaderCard";
import { Button } from "@mui/material";
import styled from "styled-components";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import NewsAdminTable from "./table/NewsAdminTable";
import { useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";

const NewsAdmin = () => {
  const navigate = useNavigate();

  return (
    <Component>
      <HeaderCard
        title="Noticias"
        leftContent={
          <Button
            variant="outlined"
            endIcon={<KeyboardArrowRightIcon />}
            onClick={() => navigate("/admin/news/create")}
          >
            Pulibcar
          </Button>
        }
      />
      <NewsAdminTable fetch />
      <Toaster position="top-right" />
    </Component>
  );
};

const Component = styled.div`
  .even-row {
    background-color: #f9f9f9;
  }

  .odd-row {
    background-color: #ffffff;
  }

  .avatar-container {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
  }
`;

export default NewsAdmin;
