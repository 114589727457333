import { Chip } from "@mui/material";
import React from "react";
import styled from "styled-components";

const SchedulType = ({ type }) => {
  return (
    <Component>
      <Chip className={`chip ${type}`} label={type} size="small" />
    </Component>
  );
};

const Component = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 100%;

  .chip {
    border-radius: 5px;
    font-weight: 700;
    font-size: 12px;
  }

  .chip.curso {
    background-color: #bef5bd;
    color: #319b40;
  }

  .chip.evento {
    background-color: #f5dcbdb8;
    color: #a0762c;
  }
`;

export default SchedulType;
