import React from "react";
import { Box, CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import styled from "styled-components";

const CustomTable = ({ rows, columns, isLoading }) => {
  return (
    <Component>
      <Box className="table-container">
        {isLoading && (
          <CircularProgressContainer>
            <CircularProgress />
          </CircularProgressContainer>
        )}
        {!isLoading && (
          <DataGrid
            rows={rows}
            columns={columns}
            rowHeight={60}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0
                ? "even-row"
                : "odd-row"
            }
            sx={{
              border: "none",
              "& .MuiDataGrid-footerContainer": {
                display: "flex",
                justifyContent: "center",
              },
              "& .MuiDataGrid-row": {
                minHeight: "100px",
              },

              ".MuiDataGrid-columnSeparator": {
                display: "none",
              },

              "& .MuiDataGrid-columnHeader": {
                backgroundColor: "#f1f5f9",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold",
                color: "#475569",
              },
            }}
            isRowSelectable={() => false}
          />
        )}
      </Box>
    </Component>
  );
};

const CircularProgressContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px 0px;
`;

const Component = styled.div`
  .table-container {
  }

  .even-row {
    background-color: #f9f9f9;
  }

  .odd-row {
    background-color: #ffffff;
  }
`;

export default CustomTable;
