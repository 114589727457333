import React from "react";
import ModalCustom from "../../../common/modals/ModalCustom";
import { useFormik } from "formik";
import instance from "../../../../config/axiosConfig";
import toast from "react-hot-toast";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import styled from "styled-components";

const ModalCreate = ({ open, onClose, onChange }) => {
  const initialValues = {
    email: null,
    password: null,
    firstname: null,
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await instance.post(`auth/register`, values, {
          withCredentials: true,
        });
        formik.resetForm();
        toast.success("El usuario se ha creado correctamente", {
          duration: 5000,
        });
        onChange();
        onClose();
      } catch (error) {
        console.error({ "Error al editar las credenciales: ": error });
        toast.error(error.response.data.error, { duration: 5000 });
      } finally {
        setSubmitting(false);
      }
    },
  });

  const allFieldsFilled = Object.values(formik.values).every((value) => value);

  return (
    <ModalCustom open={open} onClose={onClose} style={{ padding: 0 }}>
      <form onSubmit={formik.handleSubmit}>
        <Component>
          <Box className="header">
            <Typography>Crear nuevo usuario</Typography>
          </Box>
          <Box className="form-body">
            <Alert severity="info" className="alert">
              Este formulario pide los campos minimos necesarios para crear un
              usuario, luego de crearlo se debera iniciar sesion y completar los
              demas datos en el perfil.
            </Alert>

            <TextField
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              fullWidth
              label="Email"
            />
            <TextField
              name="password"
              onChange={formik.handleChange}
              value={formik.values.password}
              fullWidth
              type="password"
              label="Contraseña"
              className="password"
            />
            <TextField
              name="firstname"
              onChange={formik.handleChange}
              value={formik.values.firstname}
              fullWidth
              label="Nombre"
            />
          </Box>
          <Box className="footer">
            <Box className="change-container">
              <Button
                variant="contained"
                type="submit"
                disabled={!allFieldsFilled || formik.isSubmitting}
              >
                {formik.isSubmitting && (
                  <CircularProgress size={16} color="inherit" />
                )}
                {!formik.isSubmitting && "Confirmar"}
              </Button>
              <Button variant="outlined" color="error" onClick={onClose}>
                Cancelar
              </Button>
            </Box>
          </Box>
        </Component>
      </form>
    </ModalCustom>
  );
};

const Component = styled.div`
  width: 100%;

  .header {
    padding: 20px;
    border-bottom: 1px solid #ccc;
  }

  .alert {
    margin-bottom: 20px;
  }

  .form-body {
    padding: 20px;
  }

  .password {
    margin: 20px 0;
  }

  .footer {
  }

  .change-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }
`;

export default ModalCreate;
