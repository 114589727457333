import React from "react";
import { Avatar, Box, Typography } from "@mui/material";
import styled from "styled-components";

const AvatarBannerColumn = ({ row }) => {
  return (
    <Component>
      <Avatar src={row?.image} variant="rounded" />
    </Component>
  );
};

const Component = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-align: start;
  height: 100%;
`;

export default AvatarBannerColumn;
