import instance from "../../config/axiosConfig";
import { loggedSucces, isLoading } from "../slice/auth/loggedSlice";
import { loginError, loginStart, loginSuccess } from "../slice/auth/loginSlice";

export const login = ({ email, password }) => {
  return async (dispatch) => {
    try {
      dispatch(loginStart(true));
      const response = await instance.post(
        "auth/login",
        { email, password },
        { withCredentials: true }
      );
      dispatch(loginSuccess(response.data.token));
      window.location.href = "/";
    } catch (error) {
      dispatch(loginStart(false));
      dispatch(loginError(error));
      console.error(error);
    }
  };
};

export const getUserLogged = () => {
  return async (dispatch) => {
    // const isAuthenticated = getState((state) => state.login.isAuthenticated)
    // const {login} = getState()
    const isAuthenticated = localStorage.getItem("isAuthenticated");
    try {
      dispatch(isLoading(true));
      if (isAuthenticated) {
        const response = await instance.get("/auth/logged", {
          withCredentials: true,
        });
        const user = response.data;
        dispatch(loggedSucces(user));
      } else {
        localStorage.removeItem("isAuthenticated");
      }
    } catch (error) {
      localStorage.removeItem("isAuthenticated");
      console.log({ ERROR: error });
    }
  };
};
