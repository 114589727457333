import React from "react";
import { Box, Typography } from "@mui/material";
import styled, { keyframes } from "styled-components";

const BodyCard = ({
  children,
  title,
  subTitle,
  leftContent,
  header,
  style,
}) => {
  const isHeader = title || subTitle || leftContent || header;

  return (
    <Component>
      {isHeader && (
        <Box className={!header ? "header" : "header border"}>
          <Box>
            <Typography className="title">{title}</Typography>
            <Typography className="sub-title">{subTitle}</Typography>
          </Box>
          <Box>{leftContent}</Box>
        </Box>
      )}

      <Box className={!isHeader ? "body" : "body header"} style={style}>
        <Box style={{ width: "100%" }}>{children}</Box>
      </Box>
    </Component>
  );
};

const slideUp = keyframes`
  from {
    transform: translateY(2%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const Component = styled.div`
  // animation: ${slideUp} 0.5s ease forwards;

  border-radius: 15px;
  border: 1px solid #e2e8f0;
  overflow: hidden;
  background-color: white;
  transition: ease all 3s;

  .header {
    padding: 15px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header.border {
    border-bottom: 1px solid #e2e8f0;
  }

  .title {
    font-size: 17px;
    font-weight: 700;
    color: gray;
  }

  .sub-title {
    font-size: 14px;
    color: #0009;
  }

  .body {
    padding: 30px;
  }

  .body.header {
    padding: 0px 30px 30px 30px;
  }

  @media (max-width: 610px) {
    .header {
      padding: 15px;
    }

    .body.header {
      padding: 0px 15px 15px 15px !important;
    }

    .body {
      padding: 20px;
    }
  }
`;

export default BodyCard;
