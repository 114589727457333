import React from "react";
import { useFormik } from "formik";
import instance from "../../../config/axiosConfig";
import toast from "react-hot-toast";
import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import ModalCustom from "./ModalCustom";

const ModalUserDelete = ({ user, onClose, onFinish = () => {}, url }) => {
  const formik = useFormik({
    initialValues: { delete: "" },
    onSubmit: async () => {
      try {
        const response = await instance.delete(url, {
          withCredentials: true,
        });
        formik.resetForm();
        onFinish();
        onClose();
        toast.success(response.data.message, { duration: 5000 });
      } catch (error) {
        console.error({ "Error al eliminar la cuenta: ": error });
        toast.error(error.response.data.error, { duration: 5000 });
      }
    },
  });

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <ModalCustom open={user} onClose={onClose} title="Eliminar Usuario">
      <form onSubmit={formik.handleSubmit}>
        <ModalBody>
          <Component>
            <Alert severity="warning">
              ¿Estás seguro de que deseas eliminar esta cuenta? Esta acción es
              permanente y eliminará todos tus datos y noticias creadas. Esto no
              se puede deshacer.
            </Alert>
            <Box className="this-user">
              <Avatar src={user?.photo} alt="user" />
              <Box className="this-user-info">
                <Typography className="name">{`${user?.firstname} ${user?.lastname}`}</Typography>
                <Typography className="email">{user?.email}</Typography>
              </Box>
            </Box>
            <Typography className="confirm-type">
              Escribe{" "}
              <Typography className="user-name-confirm">
                {user?.firstname}
              </Typography>{" "}
              para confirmar
            </Typography>
            <TextField
              fullWidth
              size="small"
              name="delete"
              onChange={formik.handleChange}
              value={formik.values.delete}
            />
            <Box className="profile-bottom">
              <Box className="change-container">
                <Button
                  variant="contained"
                  type="submit"
                  color="error"
                  disabled={
                    !formik.values.delete ||
                    formik.isSubmitting ||
                    formik.values.delete !== user?.firstname
                  }
                >
                  {formik.isSubmitting && (
                    <CircularProgress size={16} color="inherit" />
                  )}
                  {!formik.isSubmitting && "Eliminar Cuenta"}
                </Button>
                <Button variant="outlined" onClick={handleClose}>
                  Cancelar
                </Button>
              </Box>
            </Box>
          </Component>
        </ModalBody>
      </form>
    </ModalCustom>
  );
};

const Component = styled.div`
  .title {
    font-size: 18px;
    color: gray;
  }

  .alert {
    margin: 20px 0px;
  }

  .this-user {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 20px;
  }
  .this-user-info {
    display: flex;
    flex-direction: column;
  }
  .name {
    font-size: 14px;
    font-weight: 700;
  }
  .email {
    font-size: 13px;
    color: gray;
  }
`;

const ModalBody = styled.div`
  .confirm-type {
    display: flex;
    gap: 8px;
    aling-items: center;
    margin-top: 20px;
  }
  .user-name-confirm {
    font-weight: 700;
  }
  .profile-bottom {
    display: flex;
    gap: 40px;
    justify-content: space-between;
    margin-top: 20px;
  }
  .change-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .label {
    font-weight: 400;
    font-size: 14px;
    color: #0009;
    // margin-bottom: 5px;
  }
`;

export default ModalUserDelete;
