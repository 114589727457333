import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import instance from "../../../../config/axiosConfig";
import toast from "react-hot-toast";
import Upload from "../../../common/upload/Upload";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { getUserLogged } from "../../../../redux/thunks/loginFetchThunk";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import CustomTextField from "../../../common/textField/CustomTextField";

const UserData = ({ user, isLoading }) => {
  const [editPhoto, setEditPhoto] = useState(false);
  const [formKey, setFormKey] = useState(Date.now());

  const dispatch = useDispatch();

  const initialValues = {
    firstname: user?.firstname,
    lastname: user?.lastname,
    photo: user?.photo,
    phone: user?.phone,
    country: user?.country,
    description: user?.description,
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await instance.put(`user/${user?.id}`, values);
        dispatch(getUserLogged());
        setFormKey(Date.now());
        formik.resetForm();
        toast.success("Se actualizo el perfil correctamente!", {
          duration: 5000,
        });
      } catch (error) {
        console.error({ "Edit profile error": error });
        toast.error("Error al actualizar el perfil", { duration: 5000 });
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleChangePhoto = (url) => {
    formik.setFieldValue("photo", url);
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <StyledContainer>
      <Box className="avatar-container">
        <Avatar src={initialValues?.photo} className="avatar" />
        <Box className="avatar-settings">
          <IconButton
            className="button-edit"
            onClick={() => setEditPhoto(true)}
          >
            <PencilSquareIcon className="icon-edit" />
          </IconButton>
        </Box>
      </Box>

      <Typography className="user-id">{`ID de usuario: #${user?.id}`}</Typography>

      <form onSubmit={formik.handleSubmit} key={formKey}>
        <Box className="dataContainer">
          <CustomTextField
            name="firstname"
            value={formik.values.firstname}
            defaultValue={user?.firstname}
            onChange={formik.handleChange}
            label="Nombre"
            fullWidth
          />

          <CustomTextField
            name="lastname"
            value={formik.values.lastname}
            defaultValue={user?.lastname}
            onChange={formik.handleChange}
            label="Apellido"
            fullWidth
          />

          <CustomTextField
            name="phone"
            value={formik.values.phone}
            defaultValue={user?.phone}
            onChange={formik.handleChange}
            label="Telefono"
            fullWidth
          />

          <CustomTextField
            name="country"
            value={formik.values.country}
            defaultValue={user?.country}
            onChange={formik.handleChange}
            label="Pais"
            fullWidth
          />

          <CustomTextField
            name="description"
            defaultValue={user?.description}
            value={formik.values.description}
            onChange={formik.handleChange}
            rows={5}
            multiline
            label="Descripcion"
            fullWidth
          />
        </Box>

        <Box className="profile-bottom">
          <Box className="change-container">
            <Button
              variant="contained"
              type="submit"
              disabled={!formik.dirty || formik.isSubmitting}
            >
              {formik.isSubmitting && (
                <CircularProgress size={16} color="inherit" />
              )}
              {!formik.isSubmitting && "Guardar Cambios"}
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => formik.resetForm()}
              disabled={!formik.dirty || formik.isSubmitting}
            >
              Cancelar
            </Button>
          </Box>
        </Box>
      </form>
      <ModalChangePhoto
        open={editPhoto}
        onClose={() => {
          // handleCancelEdit();
          setEditPhoto(false);
        }}
        onChange={handleChangePhoto}
        userId={user?.id}
      />
    </StyledContainer>
  );
};

export default UserData;

const ModalChangePhoto = ({ open, onClose, onChange, userId }) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      images: null,
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const formData = new FormData();
        formData.append("file", values.image);

        const newPhoto = await instance.put(`user/photo/${userId}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        toast.success("Se actualizo el perfil correctamente!");
        onChange(newPhoto?.data);
        formik.resetForm();
        dispatch(getUserLogged());
        onClose();
      } catch (error) {
        console.error({ "Edit photo": error });
        toast.error("Error al actualizar la foto de perfil");
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <StyledModal open={open} onClose={onClose}>
      <StyledBox>
        <form onSubmit={formik.handleSubmit}>
          <Upload onChange={(e) => formik.setFieldValue("image", e)} />
          <ButtonsEditPhoto>
            <Button variant="outlined" type="submit">
              {formik.isSubmitting && (
                <CircularProgress size={16} color="inherit" />
              )}
              {!formik.isSubmitting && "Guardar"}
            </Button>
            <Button variant="outlined" color="error" onClick={onClose}>
              Cancelar
            </Button>
          </ButtonsEditPhoto>
        </form>
      </StyledBox>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  overflow: hidden;
  outline: none;
`;

const StyledBox = styled(Box)`
  margin: 0 auto;
  background-color: white;
  border-radius: 10px;
  max-height: 90vh;
  max-width: 600px;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  outline: none;
  padding: 20px;

  @media (max-width: 1280px) {
    max-width: 80vw !important;
  }
`;

const ButtonsEditPhoto = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const StyledContainer = styled.div`
  //   max-width: 350px;
  //   width: 100%;

  .avatar-container {
    display: inline-block;
    position: relative;
  }
  .avatar {
    width: 150px;
    height: 150px;
  }
  .avatar-settings {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    z-index: 5;
    bottom: 10px;
    right: 5px;
  }

  .button-edit {
    background-color: gray;
  }
  .button-edit:hover {
    background-color: gray;
  }

  .icon-edit {
    color: white;
    width: 18px;
  }

  .user-id {
    margin: 20px 0px 40px 0px;
    font-size: 14px;
    color: #ccc;
  }

  .dataContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 40px;
  }

  .profile-bottom {
    display: flex;
    gap: 40px;
    justify-content: space-between;
  }
  .change-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;
