import { useFormik } from "formik";
import React from "react";
import BodyCard from "../../../common/bodyCard/BodyCard";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import styled from "styled-components";
import CustomQuill from "../../../common/customQuill/CustomQuill";
import { DatePicker, DateTimePicker, TimePicker } from "@mui/x-date-pickers";
import Upload from "../../../common/upload/Upload";
import CustomTextField from "../../../common/textField/CustomTextField";

const ScheduleForm = ({ initialValues, onSubmit, label, loading }) => {
  const platformOptions = ["Presencial", "Virtual"];

  const levelOptions = ["Inicial", "Media", "Avanzado", "Experto"];

  const categoryOptions = [
    "Instalaciónes simples",
    "Trabajo en equipo",
    "Seguridad y privacidad",
    "Seguridad personalizada",
    "Tecnicas y maestrias",
  ];

  const currencies = ["MXN", "USD"];

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <BodyCard
        header
        title="Detalles"
        subTitle="Titulo, Fecha, imagen..."
        leftContent={
          <Button
            variant="outlined"
            startIcon={<CloudUploadIcon />}
            type="submit"
            disabled={!formik.dirty || formik.isSubmitting}
          >
            {formik.isSubmitting && (
              <CircularProgress size={16} color="inherit" />
            )}
            {!formik.isSubmitting && label}
          </Button>
        }
      >
        {loading && (
          <CircularProgressContainer>
            <CircularProgress />
          </CircularProgressContainer>
        )}

        {!loading && (
          <Component>
            <Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item>
                    <ToggleButtonGroup
                      color="primary"
                      value={formik.values.type}
                      exclusive
                      onChange={(e, value) =>
                        formik.setFieldValue("type", value)
                      }
                      aria-label="Platform"
                    >
                      <ToggleButton value="curso">Curso</ToggleButton>
                      <ToggleButton value="evento">Evento</ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  <Grid item>
                    <ToggleButtonGroup
                      color="primary"
                      value={formik.values.isFree}
                      exclusive
                      onChange={(e, value) =>
                        formik.setFieldValue("isFree", value)
                      }
                      aria-label="Platform"
                    >
                      <ToggleButton value={true}>Gratis</ToggleButton>
                      <ToggleButton value={false}>Pago</ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>

                {formik.values.isFree === false && (
                  <Grid container spacing={2} className="price-grid">
                    <Grid item xs={12}>
                      <Alert severity="warning">
                        Como para esta estapa no se implemento un sistema de
                        pagos la unica forma de cobrar el valor de esta agenda
                        sera en efectivo (Presencial), se recomienda aclarar
                        esto en el detalle de la agenda en caso de que sea una
                        agenda de pago.
                      </Alert>
                    </Grid>
                    <Grid item>
                      <CustomTextField
                        name="price"
                        type="number"
                        value={formik.values.price}
                        label="Costo (solo numeros)"
                        onChange={formik.handleChange}
                      />
                    </Grid>

                    <Grid item>
                      <FormControl fullWidth>
                        <InputLabel id="label-divisa">Divisa</InputLabel>
                        <Select
                          label="Divisa"
                          labelId="label-divisa"
                          value={formik.values.currency}
                          onChange={(e) =>
                            formik.setFieldValue("currency", e.target.value)
                          }
                        >
                          {currencies.map((e) => (
                            <MenuItem value={e}>{e}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}

                {formik.values.type === "evento" && (
                  <Grid container spacing={2}>
                    <Grid item>
                      <DateTimePicker
                        label="Fecha y hora de inicio"
                        fullWidth
                        value={formik.values.start_date}
                        onChange={(date) =>
                          formik.setFieldValue("start_date", date)
                        }
                      />
                    </Grid>
                    <Grid item>
                      <CustomTextField
                        name="duration"
                        type="number"
                        label="Duracion/horas (opcional)"
                        fullWidth
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </Grid>
                )}

                {formik.values.type === "curso" && (
                  <Box className="box-course-time">
                    <Grid container spacing={2}>
                      <Grid item>
                        <DatePicker
                          label="Fecha de inicio"
                          name="start_date"
                          value={formik.values.start_date}
                          onChange={(date) =>
                            formik.setFieldValue("start_date", date)
                          }
                        />
                      </Grid>
                      <Grid item>
                        <DatePicker
                          label="Fecha de finalizacion"
                          name="end_date"
                          value={formik.values.end_date}
                          onChange={(date) =>
                            formik.setFieldValue("end_date", date)
                          }
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item>
                        <TimePicker
                          label="Hora de inicio"
                          name="start_hour"
                          value={formik.values.start_hour}
                          onChange={(hour) =>
                            formik.setFieldValue("start_hour", hour)
                          }
                        />
                      </Grid>
                      <Grid item>
                        <TimePicker
                          label="Hora de finalizacion"
                          name="end_hour"
                          value={formik.values.hour}
                          onChange={(hour) =>
                            formik.setFieldValue("end_hour", hour)
                          }
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item>
                        <CustomTextField
                          name="meetings"
                          type="number"
                          label="Encuentro totales"
                          onChange={formik.handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}

                <Upload
                  title="Portada"
                  value={formik.values.cover}
                  onChange={(e) => formik.setFieldValue("cover", e)}
                />

                <CustomTextField
                  name="title"
                  placeholder="Titulo"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  size="medium"
                />

                {formik.values.type === "curso" && (
                  <FormControl>
                    <InputLabel id="label-dificultad">Dificultad</InputLabel>
                    <Select
                      value={formik.values.lavel}
                      labelId="label-dificultad"
                      label="Dificultad"
                      id="medio"
                      fullWidth
                      onChange={(e) =>
                        formik.setFieldValue("difficulty", e.target.value)
                      }
                    >
                      {levelOptions.map((e, i) => {
                        return (
                          <MenuItem key={i} value={e}>
                            {e}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}

                {formik.values.type === "evento" && (
                  <FormControl>
                    <InputLabel id="label-category">Categoria</InputLabel>
                    <Select
                      value={formik.values.category}
                      labelId="label-category"
                      label="Categoria"
                      id="category"
                      fullWidth
                      onChange={(e) =>
                        formik.setFieldValue("category", e.target.value)
                      }
                    >
                      {categoryOptions.map((e, i) => {
                        return (
                          <MenuItem key={i} value={e}>
                            {e}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}

                <FormControl>
                  <InputLabel id="label-medio">Formato</InputLabel>
                  <Select
                    value={formik.values.platform}
                    labelId="label-medio"
                    label="Formato"
                    id="medio"
                    fullWidth
                    onChange={(e) =>
                      formik.setFieldValue("platform", e.target.value)
                    }
                  >
                    {platformOptions.map((e, i) => {
                      return (
                        <MenuItem key={i} value={e}>
                          {e}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <CustomTextField
                  name="detail"
                  label="Detalle (280 caracteres)"
                  value={formik.values.detail}
                  fullWidth
                  multiline
                  rows={3}
                  onChange={formik.handleChange}
                  inputProps={{ maxLength: 280 }}
                />

                <CustomQuill
                  value={formik.values.description}
                  onChange={(e) => formik.setFieldValue("description", e)}
                />
              </Box>
            </Box>
          </Component>
        )}
      </BodyCard>
    </form>
  );
};

const CircularProgressContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

const Component = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;

  .switch {
    margin: 20px 0;
  }

  .price-grid {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px dashed #ccc;
  }

  .box-course-time {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .label {
    font-size: 14px;
    color: #0009;
    margin-bottom: 8px;
  }

  .button-gruop {
    margin-top: 20px;
  }

  .avatar-container {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
  }

  .box-container {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .even-row {
    background-color: #f9f9f9;
  }

  .odd-row {
    background-color: #ffffff;
  }
`;

export default ScheduleForm;
