import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import styled from "styled-components";

const MenuCustom = ({ options }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const iconsDictionary = {
    view: <VisibilityIcon className="icon" />,
    edit: <EditIcon className="icon" />,
    delete: <DeleteIcon className="icon" />,
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        MenuListProps={{
          style: {
            padding: "10px 0",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.label}
            onClick={option.onClick}
            disabled={option.disabled}
          >
            <MenuStyled>
              {iconsDictionary[option.icon]}
              {option.label}
            </MenuStyled>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

const MenuStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #474747;

  .icon {
    font-size: 18px;
  }
`;

export default MenuCustom;
