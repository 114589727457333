import React, { useEffect, useRef, useState } from "react";
import PublishIcon from "@mui/icons-material/Publish";
import { Box, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "styled-components";

const Upload = ({ title, onChange, value, style }) => {
  const inputRef = useRef(null);
  const [imagePreview, setImagePreview] = useState(value);
  const [drag, setDrag] = useState(false);

  useEffect(() => {
    if (value) {
      setImagePreview(value);
    }
  }, [value]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    onChange(file);

    const reader = new FileReader();
    reader.onload = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <Component>
      <div className="upload-component" style={style}>
        {title && <span className="upload-title">{title}</span>}

        {imagePreview ? (
          // --------- IMAGE PREVIEWER -------------------------------------------
          <div className="upload-image-container">
            <div className="upload-image">
              <img src={imagePreview} alt="preview" />
            </div>
            <Box>
              <IconButton color="error" className="delete-button">
                <DeleteIcon />
              </IconButton>
            </Box>
          </div>
        ) : (
          // --------- DROP AREA IMAGE UPLOADER ----------------------------------
          <>
            <div
              className={!drag ? "upload-dropArea" : "upload-dropArea active"}
            >
              <PublishIcon style={{ color: "#dbdfe9", fontSize: "35px" }} />

              <span className="upload-dropArea-title">
                arrastre o añada una imagen
              </span>
              <input
                type="file"
                // accept="image"
                ref={inputRef}
                onChange={handleFileChange}
                onDragEnter={() => setDrag(true)}
                onDragLeave={() => setDrag(false)}
              />
            </div>
          </>
        )}
      </div>
    </Component>
  );
};

const Component = styled.div`
  .upload-image-container {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid rgb(209, 209, 209);
  }

  .upload-image {
    display: inline-flex;
    overflow: hidden;
    border-radius: 10px;
    height: 168px;
  }

  .delete-button {
    border: 1px solid #ffa3a3;
  }

  .upload-title {
    font-weight: 400;
    font-size: 14px;
    color: #0009;
    display: block;
    margin-bottom: 12px;
  }

  .upload-dropArea {
    background-color: #f8fafc;
    height: 200px;
    border-radius: 10px;
    transition: all ease 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    position: relative;
    border: 1px dashed #d4d6db;
  }

  .upload-dropArea.active {
    background-color: #e0e6f0;
  }

  .upload-dropArea > input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .upload-button {
    margin-top: 0px;
    padding: 10px 25px;
    border: none;
    border-radius: 5px;
    color: white;
    background-color: rgb(13, 122, 210);
    cursor: pointer;
    outline: none;
    transition: all ease 0.3s;
    z-index: 1;
  }

  .upload-button:hover {
    background-color: rgb(16, 51, 131);
  }

  .upload-dropArea-title {
    font-size: 14px;
    font-weight: 400;
    color: #b7bed2;
    margin-top: 20px;
    pointer-events: none;
  }
`;

export default Upload;
