import React, { useState } from "react";
import { Box } from "@mui/material";
import MenuCustom from "../../../common/menu/MenuCustom";
import AvatarNameColumn from "./AvatarNameColumn";
import DateColumn from "../../../common/customTable/DateColumn";
import RolColumn from "./RolColumn";
import CustomTable from "../../../common/customTable/CustomTable";
import RolEditModal from "./RolEditModal";
import ModalUserDelete from "../../../common/modals/ModalUserDelete";

const UserAdminTable = ({ userlogged, allUsers, isLoading, fetchData }) => {
  const [userDelete, setUserDelete] = useState(null);
  const [userEdit, setUserEdit] = useState(null);

  const rows = allUsers
    ?.map((user) => {
      return {
        id: user?.id,
        name: `${user?.firstname} ${user?.lastname}`,
        firstname: user?.firstname,
        email: user?.email,
        photo: user?.photo,
        rol: user?.rol,
        createdAt: user?.createdAt,
        country: user?.country ? user?.country : "-",
      };
    })
    .reverse();

  const permissions =
    userlogged?.rol === "super-admin" || userlogged?.rol === "admin";

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.8,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "photo",
      headerName: "Usuario",
      renderCell: (params) => <AvatarNameColumn user={params.row} />,
      flex: 1.5,
      minWidth: 200,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "createdAt",
      headerName: "Fecha",
      renderCell: (params) => <DateColumn date={params.value} />,
      flex: 1,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
    },
    {
      field: "country",
      headerName: "Pais",
      flex: 1,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
    },
    {
      field: "rol",
      headerName: "Rol",
      renderCell: (params) => <RolColumn rol={params.value} />,
      flex: 1,
      minWidth: 110,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
    },
    {
      headerName: "Menu",
      renderCell: (params) => {
        const options = [
          {
            label: "Editar",
            disabled: !permissions,
            onClick: () => {
              setUserEdit(params.row);
            },
            icon: "edit",
          },
          {
            label: "Eliminar",
            disabled: !permissions,
            onClick: () => {
              setUserDelete(params.row);
            },
            icon: "delete",
          },
        ];
        return <MenuCustom item={params} options={options} />;
      },
      flex: 0.8,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  return (
    <Box>
      <CustomTable rows={rows} columns={columns} isLoading={isLoading} />
      <ModalUserDelete
        user={userDelete}
        onClose={() => {
          setUserDelete(null);
        }}
        url={`auth/user/${userDelete?.id}`}
        onFinish={fetchData}
      />
      <RolEditModal user={userEdit} onClose={() => setUserEdit(null)} />
    </Box>
  );
};

export default UserAdminTable;
