import { useFormik } from "formik";
import React, { useState } from "react";
import instance from "../../../../config/axiosConfig";
import styled from "styled-components";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { getUserLogged } from "../../../../redux/thunks/loginFetchThunk";
import CustomTextField from "../../../common/textField/CustomTextField";

const UserCredentials = ({ user, isLoading }) => {
  const dispatch = useDispatch();
  const [actualizationKey, setActualizationKey] = useState(Date.now());

  const initialValues = {
    newEmail: "",
    currentEmail: user?.email,
    newPassword: "",
    currentPassword: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const response = await instance.put(
          `auth/credentials/${user?.id}`,
          values,
          {
            withCredentials: true,
          }
        );
        dispatch(getUserLogged());
        formik.resetForm();
        setActualizationKey(Date.now());
        toast.success(response.data.message, { duration: 5000 });
      } catch (error) {
        console.error({ "Error al editar las credenciales: ": error });
        toast.error(error.response.data.error, { duration: 5000 });
      } finally {
        setSubmitting(false);
      }
    },
  });

  if (isLoading) return <CircularProgress />;

  return (
    <Component>
      <form onSubmit={formik.handleSubmit}>
        <Box className="dataContainer">
          <Typography className="label">Actualizar email</Typography>
          <CustomTextField
            name="currentEmail"
            size="small"
            label="Email actual"
            value={formik.values.currentEmail}
            disabled
            type="email"
            fullWidth
          />
          <CustomTextField
            name="newEmail"
            value={formik.values.newEmail}
            onChange={formik.handleChange}
            size="small"
            label="Nuevo Email"
            fullWidth
          />

          <Divider />

          <Typography className="label">Actualizar contraseña</Typography>
          <CustomTextField
            name="currentPassword"
            value={formik.values.currentPassword}
            onChange={formik.handleChange}
            size="small"
            label="Contraseña Actual"
            fullWidth
            type="password"
          />
          <CustomTextField
            name="newPassword"
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            size="small"
            label="Nueva contraseña"
            fullWidth
            type="password"
          />
        </Box>

        <Box className="profile-bottom">
          <Box className="change-container">
            <Button
              variant="contained"
              type="submit"
              disabled={!formik.dirty || formik.isSubmitting}
            >
              {formik.isSubmitting && (
                <CircularProgress size={16} color="inherit" />
              )}
              {!formik.isSubmitting && "Guardar Cambios"}
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => formik.resetForm()}
              disabled={!formik.dirty || formik.isSubmitting}
            >
              Cancelar
            </Button>
          </Box>
        </Box>
      </form>
    </Component>
  );
};

const Component = styled.div`
  .dataContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 40px;
  }

  .profile-bottom {
    display: flex;
    gap: 40px;
    justify-content: space-between;
  }
  .change-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .label {
    font-weight: 400;
    font-size: 14px;
    color: #0009;
    // margin-bottom: 5px;
  }
`;

export default UserCredentials;
