import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";

const NewsColumn = ({ news }) => {
  return (
    <Component>
      <Avatar src={news?.cover} variant="rounded" />
      <Box className="container">
        <Typography className="name">{news?.title}</Typography>
        <Typography className="email">{news?.subtitle}</Typography>
      </Box>
    </Component>
  );
};

const Component = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  text-align: start;
  height: 100%;

  .container {
    font-size: 14px;
  }
  .name {
    font-size: 13px;
  }
  .email {
    font-size: 13px;
    color: gray;
  }
`;

export default NewsColumn;
