import React from "react";
import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import moment from "moment";

const DateColumn = ({ date }) => {
  const dateFormat = moment(date).format("DD-MM-YYYY");
  const hoursFormat = moment(date).format("h:mm a");
  return (
    <Component>
      <Typography className="date">{dateFormat}</Typography>
      <Typography className="hours">{hoursFormat}</Typography>
    </Component>
  );
};

const Component = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .date {
    font-size: 13px;
  }

  .hours {
    font-size: 13px;
    color: gray;
  }
`;

export default DateColumn;
