import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import BodyCard from "../../../common/bodyCard/BodyCard";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useFormik } from "formik";
import Upload from "../../../common/upload/Upload";

const PartnerForm = ({ initialValues, onSubmit, label, isLoading }) => {
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <BodyCard
        title="Datos del Partner"
        subTitle="Nombre, logo, etc..."
        header
        leftContent={
          <Button
            variant="outlined"
            startIcon={<CloudUploadIcon />}
            type="submit"
            disabled={!formik.dirty || formik.isSubmitting}
          >
            {formik.isSubmitting && (
              <CircularProgress size={16} color="inherit" />
            )}
            {!formik.isSubmitting && label}
          </Button>
        }
      >
        <Box className="button-gruop">
          <Typography className="label">Tipo</Typography>
          <ToggleButtonGroup
            color="primary"
            value={formik.values.type}
            exclusive
            onChange={(_e, value) => formik.setFieldValue("type", value)}
            aria-label="Platform"
          >
            <ToggleButton value={0}>Socios Integradores</ToggleButton>
            <ToggleButton value={1}>Aliados Corporativos</ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <Upload
          title="Se recomienda imagenes en formato .png"
          onChange={(e) => {
            formik.setFieldValue("logo", e);
          }}
          value={formik.values.image}
        />

        <TextField
          name="name"
          lable="Nombre"
          fullWidth
          value={formik.values.name}
          onChange={formik.handleChange}
        />
      </BodyCard>
    </form>
  );
};

const Component = styled.div``;

export default PartnerForm;
