import React from "react";

import styled from "styled-components";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import instance from "../../../../config/axiosConfig";
import toast from "react-hot-toast";
import ModalCustom from "../../../common/modals/ModalCustom";
import { useSelector } from "react-redux";

const RolEditModal = ({ user, onClose }) => {
  const userLogged = useSelector((state) => state.logged.user);

  const formik = useFormik({
    initialValues: { rol: user?.rol },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const response = await instance.put(`user/${user?.id}`, values, {
          withCredentials: true,
        });
        fetchData();
        onClose();
        toast.success(response.data.message, { duration: 5000 });
      } catch (error) {
        toast.error(error.response.data.error, { duration: 5000 });
        setSubmitting(false);
      }
    },
  });

  return (
    <ModalCustom open={user} onClose={onClose} title="Editar">
      <form onSubmit={formik.handleSubmit}>
        <Component>
          <Typography className="title">{`Nuevo rol para "${user?.firstname}"`}</Typography>

          {/* ----------------------------------------- */}

          <FormControl fullWidth>
            <InputLabel id="rol-label">Roles</InputLabel>
            <Select
              labelId="rol-label"
              id="rol"
              name="rol"
              value={formik.values.rol}
              label="Roles"
              onChange={formik.handleChange}
            >
              <MenuItem value="user">user</MenuItem>
              <MenuItem value="admin">admin</MenuItem>
              {userLogged?.rol === "super-admin" && (
                <MenuItem value="super-admin">super-admin</MenuItem>
              )}
            </Select>
          </FormControl>

          {/* ----------------------------------------- */}

          <Box className="change-container">
            <Button
              variant="outlined"
              type="submit"
              disabled={!formik.dirty || formik.isSubmitting}
            >
              {formik.isSubmitting && (
                <CircularProgress size={16} color="inherit" />
              )}
              {!formik.isSubmitting && "Guardar"}
            </Button>
            <Button variant="outlined" color="error" onClick={onClose}>
              Cancelar
            </Button>
          </Box>
        </Component>
      </form>
    </ModalCustom>
  );
};

const Component = styled.div`
  .title {
    text-align: center;
    margin-bottom: 20px;
  }

  .change-container {
    margin-top: 40px;
    display: flex;
    gap: 20px;
  }
`;

export default RolEditModal;
