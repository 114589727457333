import { Avatar, Tooltip } from "@mui/material";
import React from "react";
import styled from "styled-components";

const NewsCreatedBy = ({ by }) => {
  return (
    <Component>
      <Tooltip
        title={`${by.firstname} ${by.lastname}`}
        disableInteractive
        arrow
      >
        <Avatar src={by?.photo} />
      </Tooltip>
    </Component>
  );
};

const Component = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export default NewsCreatedBy;
