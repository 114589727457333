import instance from "../../config/axiosConfig";
import { setAllUser, setLoadingUsers } from "../slice/userSlice";

export const getAllUsers = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoadingUsers(true));
      const response = await instance.get("user");
      const users = response.data;
      dispatch(setAllUser(users));
    } catch (error) {
      console.log({ ERROR: error });
    } finally {
      dispatch(setLoadingUsers(false));
    }
  };
};
