import React, { useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import ModalCustom from "./ModalCustom";
import instance from "../../../config/axiosConfig";
import styled from "styled-components";
import toast, { Toaster } from "react-hot-toast";

const ModalDelete = ({ open, onClose, onFinish, url, body, style }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await instance.delete(url);
      toast.success("Se elimino correctamente");
      onClose();
      onFinish();
    } catch (error) {
      toast.error("Error al eliminar");
      console.log({ error: error });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalCustom open={open} onClose={onClose} style={style} title="Eliminar">
      <Component>
        <Typography className="delete-body">{body}</Typography>

        {/* ---------------- button container ---------------- */}
        <Box className="button-container">
          <Button variant="outlined" color="error" onClick={handleSubmit}>
            {isLoading ? <CircularProgress size={20} /> : "Eliminar"}
          </Button>
          <Button variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
        </Box>
      </Component>
      <Toaster position="top-right" />
    </ModalCustom>
  );
};

const Component = styled.div`
  .delete-header {
    background-color: red;
  }
  .delete-body {
    font-size: 20px;
    color: gray;
    margin-bottom: 40px;
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
`;

export default ModalDelete;
