import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { Box, Typography } from "@mui/material";
import styled from "styled-components";

const CustomAccordion = ({ values }) => {
  const frequents = [
    {
      title: "No se como inscribirme",
      body: 'Para incribirte toca en el boton que dice "Pedir asesoramiento, este te llevara a whatsapp."',
    },
    {
      title: "Tiene costo y no se como pagar",
      body: "Si Tiene costo te pedimos que te comuniques a nuestro whatsapp para asesorarte y puedas acceder a este evento/curso.",
    },
  ];

  return (
    <Component>
      <h2 className="frecuents">Preguntas Frecuentes</h2>
      {!values
        ? frequents?.map((e, i) => (
            <Accordion key={i} variant="outlined">
              <AccordionSummary>{e?.title}</AccordionSummary>
              <AccordionDetails>{e?.body}</AccordionDetails>
            </Accordion>
          ))
        : values?.map((e, i) => (
            <Accordion key={i}>
              <AccordionSummary>{e?.title}</AccordionSummary>
              <AccordionDetails>{e?.body}</AccordionDetails>
            </Accordion>
          ))}
    </Component>
  );
};

const Component = styled.div`
  .frecuents {
    margin-bottom: 20px;
  }
`;

export default CustomAccordion;
