import { useDispatch } from "react-redux";
import { getBanners } from "../../../../redux/thunks/bannersFetchThunk";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MenuCustom from "../../../common/menu/MenuCustom";
import ActiveColumn from "../../../common/customTable/ActiveColumn";
import DateColumn from "../../../common/customTable/DateColumn";
import AvatarBannerColumn from "./AvatarBannerColumn";
import {
  Box,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import ModalDelete from "../../../common/modals/ModalDelete";
import ModalEdit from "../../../common/modals/ModalEdit";
import CustomTable from "../../../common/customTable/CustomTable";
import styled from "styled-components";

const BannerAdminTable = () => {
  const dispatch = useDispatch();

  const fetchData = async () => {
    dispatch(getBanners());
  };

  useEffect(() => {
    fetchData();
  }, []);

  const banners = useSelector((state) => state.banners.allBanners);
  const isLoading = useSelector((state) => state.banners.isLoading);

  const rows = banners
    ?.map((banner) => {
      return {
        id: banner?.id,
        image: banner?.url,
        reference: banner?.reference,
        isActive: banner?.isActive,
        createdAt: banner?.createdAt,
      };
    })
    .reverse();

  const [deleteId, setDeleteId] = useState(null);
  const [bannerEdit, setBannerEdit] = useState(null);

  const handleCloseDelete = () => {
    setDeleteId(null);
  };
  const handleCloseEdit = () => {
    setBannerEdit(null);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.8,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "image",
      headerName: "Imagen",
      renderCell: (params) => <AvatarBannerColumn row={params.row} />,
      flex: 1,
      minWidth: 120,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "createdAt",
      headerName: "Creado",
      renderCell: (params) => <DateColumn date={params.value} />,
      flex: 1,
      minWidth: 120,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "reference",
      headerName: "Referencia",
      flex: 1,
      minWidth: 120,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "isActive",
      headerName: "Activo",
      renderCell: (params) => <ActiveColumn value={params.value} />,
      flex: 0.8,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      headerName: "Menu",
      renderCell: (params) => {
        const options = [
          {
            label: "Editar",
            onClick: () => {
              setBannerEdit(params.row);
            },
            icon: "edit",
          },
          {
            label: "Eliminar",
            onClick: () => {
              setDeleteId(params.row.id);
            },
            icon: "delete",
          },
        ];
        return <MenuCustom item={params} options={options} />;
      },
      sortable: false,
      flex: 0.8,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
    },
  ];

  const handleEditReference = (event) => {
    const value = event.target.value;
    setBannerEdit({
      ...bannerEdit,
      reference: value,
    });
  };

  const handleEditActive = (event) => {
    const value = event.target.checked;
    setBannerEdit({
      ...bannerEdit,
      isActive: value,
    });
  };

  return (
    <Box>
      <ModalDelete
        open={deleteId}
        onClose={handleCloseDelete}
        onFinish={fetchData}
        url={`banner/${deleteId}`}
        title={`Estas seguro que deseas eliminar el banner con id: "${deleteId}"`}
        style={{ maxWidth: "300px" }}
      />

      <ModalEdit
        open={bannerEdit}
        onClose={handleCloseEdit}
        onFinish={fetchData}
        url={`banner/${bannerEdit?.id}`}
        value={bannerEdit}
      >
        <EditComponent>
          <Typography className="edit-title">{`Estas editando el banner con id: "${bannerEdit?.id}"`}</Typography>
          <FormControlLabel
            control={<Switch checked={bannerEdit?.isActive} />}
            label="banner activo?"
            className="switch"
            onChange={handleEditActive}
          />
          <TextField
            label="Referencia"
            fullWidth
            value={bannerEdit?.reference}
            onChange={handleEditReference}
            className="edit-reference"
          />
        </EditComponent>
      </ModalEdit>

      <CustomTable rows={rows} columns={columns} isLoading={isLoading} />
    </Box>
  );
};

const EditComponent = styled.div`
  .edit-title {
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
  }

  .edit-reference {
    margin: 20px 0;
  }
`;

export default BannerAdminTable;
