import React, { useEffect, useState, useRef } from "react";
import "../styles/pages/News.css";
import CardNew from "../components/new/CardNew";
import Navbar from "../components/common/navbar/Navbar";
import { Footer } from "../components/home/Footer";
import moment from "moment";
import searchIcon from "../assets/search/search-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { getAllNews } from "../redux/thunks/newsFetchThunk";
import { ClipLoader } from "react-spinners";
import { Helmet } from "react-helmet";
import BounceLoader from "react-spinners/BounceLoader";
import HelmetDetail from "../utils/HelmetDetail";

// ----------------------- SEARCH COMPONENT ---------------------------

const SearchNews = ({ handleChange, news }) => {
  const [isSearching, setIsSearching] = useState(false);
  const searchContainerRef = useRef(null);

  const handleSubSearch = (event) => {
    handleChange(event);
    const search = event.target.value;
    if (search.length >= 1) setIsSearching(true);
    else setIsSearching(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        setIsSearching(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleCardClick = (id) => {
    // Navegar a la card de la noticia con el ID correspondiente
    window.location.href = `/noticias/${id}`;
  };

  return (
    <div className="search-news-component">
      <div className="search-news" ref={searchContainerRef}>
        <input
          placeholder="Buscar título de la noticia"
          onChange={handleSubSearch}
        />
        <div className="search-icon-div">
          <img src={searchIcon} alt="Search Icon" />
        </div>
      </div>
    </div>
  );
};

export default SearchNews;

export const SearchNewsResponsive = ({ handleChange, news }) => {
  const [isSearching, setIsSearching] = useState(false);
  const searchContainerRef = useRef(null);

  const handleSubSearch = (event) => {
    handleChange(event);
    const search = event.target.value;
    if (search.length >= 1) setIsSearching(true);
    else setIsSearching(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        setIsSearching(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleCardClick = (id) => {
    // Navegar a la card de la noticia con el ID correspondiente
    window.location.href = `/noticias/${id}`;
  };

  return (
    <div className="search-news-component-responsive">
      <div className="search-news" ref={searchContainerRef}>
        <input
          placeholder="Buscar título de la noticia"
          onChange={handleSubSearch}
        />
        <div className="search-icon-div">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M15.5 14H14.71L14.43 13.73C15.4439 12.554 16.0011 11.0527 16 9.5C16 8.21442 15.6188 6.95772 14.9046 5.8888C14.1903 4.81988 13.1752 3.98676 11.9874 3.49479C10.7997 3.00282 9.49279 2.87409 8.23192 3.1249C6.97104 3.3757 5.81285 3.99477 4.90381 4.90381C3.99477 5.81285 3.3757 6.97104 3.1249 8.23192C2.87409 9.49279 3.00282 10.7997 3.49479 11.9874C3.98676 13.1752 4.81988 14.1903 5.8888 14.9046C6.95772 15.6188 8.21442 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z"
              fill="#0F0F0F"
            />
          </svg>
        </div>
      </div>
      {/* ------- resultados ------- */}
      {isSearching && (
        <div className="search-result-container">
          {news.map((item, index) => {
            return (
              <div
                key={index}
                className="search-result-item"
                onClick={() => handleCardClick(item.id)}
              >
                <div className="search-result-image-div">
                  <img src={item?.cover} alt={item?.title} loading="lazy" />
                </div>
                <div>
                  <span className="back-search-div-card">{item?.title}</span>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

// ----------------------- NEWS COMPONENT ---------------------------
export const News = () => {
  const dispatch = useDispatch();

  const [isLoaded, setIsLoaded] = useState(false);
  const [newsData, setNewsData] = useState([]);
  const [displayedNewsCount, setDisplayedNewsCount] = useState(8);
  const [mostrarOpciones, setMostrarOpciones] = useState(false);
  const [opcionSeleccionada, setOpcionSeleccionada] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [loading, setLoading] = useState(true);
  const news = useSelector((state) => state.news.news);
  const isLoading = useSelector((state) => state.news.isLoading);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(getAllNews());
      setIsLoaded(true);
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (isLoaded) {
      setNewsData(news || []);
    }
  }, [isLoaded, news]);

  const cardRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        expanded &&
        cardRef.current &&
        !cardRef.current.contains(event.target)
      ) {
        cardRef.current = null; // Limpiamos la referencia cuando se hace clic fuera de la tarjeta
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [cardRef]);

  const handleSearch = (event) => {
    const searched = event.target.value.toLowerCase();

    let filteredResult = [];

    switch (opcionSeleccionada) {
      case "Todo":
        filteredResult = news.filter((item) =>
          item.title.toLowerCase().includes(searched)
        );
        break;
      case "Mas antiguos":
        filteredResult = news
          .filter((item) => item.title.toLowerCase().includes(searched))
          .reverse();
        break;
      case "Por FISEL":
        filteredResult = news
          .filter((item) => item.title.toLowerCase().includes(searched))
          .filter((item) => item.isByFisel === true);
        break;
      case "Por otros":
        filteredResult = news
          .filter((item) => item.title.toLowerCase().includes(searched))
          .filter((item) => item.isByFisel !== true);
        break;
      default:
        filteredResult = news.filter((item) =>
          item.title.toLowerCase().includes(searched)
        );
        break;
    }

    setNewsData(filteredResult);
  };

  //PARA RESPONSIVE

  const handleSearchResponsive = (event) => {
    // Actualiza el término de búsqueda cuando se ingresa texto en el buscador
    setSearchTerm(event.target.value.toLowerCase());
  };

  const aplicarFiltro = () => {
    // Realizar el filtrado de noticias según el término de búsqueda y la opción seleccionada
    let filteredResult = [];

    switch (opcionSeleccionada) {
      case "Todo":
        filteredResult = news.filter((item) =>
          item.title.toLowerCase().includes(searchTerm)
        );
        break;
      case "Mas antiguos":
        filteredResult = news
          .filter((item) => item.title.toLowerCase().includes(searchTerm))
          .reverse();
        break;
      case "Sobre FISEL":
        filteredResult = news
          .filter((item) => item.title.toLowerCase().includes(searchTerm))
          .filter((item) => item.isByFisel !== null);
        break;
      case "Sobre Afiliados":
        filteredResult = news
          .filter((item) => item.title.toLowerCase().includes(searchTerm))
          .filter((item) => item.isByFisel === null);
        break;
      default:
        filteredResult = news.filter((item) =>
          item.title.toLowerCase().includes(searchTerm)
        );
        break;
    }

    // Actualizar newsData con los resultados filtrados
    setNewsData(filteredResult);
    setExpanded(!expanded);
  };

  const handleShowMore = () => {
    setDisplayedNewsCount((prevCount) => prevCount + 8);
  };

  const toggleOpciones = () => {
    setMostrarOpciones(!mostrarOpciones);
  };

  const seleccionarOpcion = (opcion) => {
    setOpcionSeleccionada(opcion);
    setMostrarOpciones(false);

    // Reiniciar newsData al estado original
    setNewsData([...news]);

    // Realizar el filtrado de noticias según la opción seleccionada
    let filteredNews = [];

    switch (opcion) {
      case "Todo":
        filteredNews = [...news];
        break;
      case "Mas antiguos":
        filteredNews = [...news].reverse();
        break;
      case "Sobre FISEL":
        filteredNews = [...news].filter((item) => item.isByFisel !== null);
        break;
      case "Sobre Afiliados":
        filteredNews = [...news].filter((item) => item.isByFisel === null);
        break;
      default:
        break;
    }
    // Actualizar el estado con las noticias filtradas
    setNewsData(filteredNews);
    setExpanded(false);
  };

  const handleClose = () => {
    setExpanded(false);
  };

  const handleToggle = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  function obtenerNombreMesEnEspanol(mes) {
    const nombresMeses = [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ];
    return nombresMeses[mes];
  }

  if (loading) {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100vh",
          }}
        >
          <BounceLoader color="#0e2b60" />
        </div>
      ) : (
        <>
          <Navbar />
          <HelmetDetail
            tabTitle="Fisel | Noticias"
            title="Las mejores noticias para integradores de la seguridad - FISEL"
            description="FISEL ofrece las últimas noticias del mundo de la seguridad, integradores, tecnologia y de mas relacionados en tiempo real."
            type="website"
          />
          <div className="position-card-news">
            <div className="back-news">
              <h1 className="h1-news">Noticias</h1>
              <p className="p-news">{`Últimas noticias de ${obtenerNombreMesEnEspanol(
                moment().month()
              )}`}</p>
              <div
                className={`search-icon-div-responsive ${
                  expanded ? "expanded" : ""
                }`}
                onClick={handleToggle}
              >
                {expanded === false && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M15.5 14H14.71L14.43 13.73C15.4439 12.554 16.0011 11.0527 16 9.5C16 8.21442 15.6188 6.95772 14.9046 5.8888C14.1903 4.81988 13.1752 3.98676 11.9874 3.49479C10.7997 3.00282 9.49279 2.87409 8.23192 3.1249C6.97104 3.3757 5.81285 3.99477 4.90381 4.90381C3.99477 5.81285 3.3757 6.97104 3.1249 8.23192C2.87409 9.49279 3.00282 10.7997 3.49479 11.9874C3.98676 13.1752 4.81988 14.1903 5.8888 14.9046C6.95772 15.6188 8.21442 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z"
                      fill="#0F0F0F"
                    />
                  </svg>
                )}
              </div>
              {expanded && (
                <div className="search-responsive-column" ref={cardRef}>
                  <SearchNewsResponsive
                    handleChange={handleSearchResponsive}
                    news={newsData}
                  />
                  <p className="p-search-responsive">Filtrar</p>
                  <div className="div-search-responsive">
                    <input
                      type="radio"
                      name="transporte"
                      className="input-responsive-search"
                      onClick={() => setOpcionSeleccionada("Todo")}
                    />
                    <span class="placeholder-search">Todo</span>
                    <input
                      type="radio"
                      name="transporte"
                      className="input-responsive-search"
                      onClick={() => setOpcionSeleccionada("Mas antiguos")}
                    />
                    <span class="placeholder-search">Mas Antiguos</span>
                  </div>
                  <div className="div-search-responsive">
                    <input
                      type="radio"
                      name="transporte"
                      className="input-responsive-search"
                      onClick={() => setOpcionSeleccionada("Sobre FISEL")}
                    />
                    <span class="placeholder-search">Sobre FISEL</span>
                  </div>
                  <div className="div-search-responsive">
                    <input
                      type="radio"
                      name="transporte"
                      className="input-responsive-search"
                      onClick={() => setOpcionSeleccionada("Sobre Afiliados")}
                    />
                    <span class="placeholder-search">Sobre Afiliados</span>
                  </div>
                  <div className="position-b-responsive">
                    <button
                      className="b-responsive-cancel"
                      onClick={handleClose}
                    >
                      Cancelar
                    </button>
                    <button
                      className="b-responsive-aplication"
                      onClick={aplicarFiltro}
                    >
                      Aplicar
                    </button>
                  </div>
                </div>
              )}

              {!isLoading && (
                <div className="width-news">
                  <div className="back-contenedor-filter-search">
                    <div className="contenedor-filter-search">
                      <button
                        className="bottom-filter-new"
                        onClick={toggleOpciones}
                      >
                        {opcionSeleccionada
                          ? opcionSeleccionada
                          : "Mostrar opciones"}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            d="M15.7501 3.74956L2.25006 3.74956C2.11338 3.74998 1.9794 3.78768 1.86255 3.85859C1.7457 3.9295 1.6504 4.03093 1.58691 4.15197C1.52342 4.27301 1.49414 4.40908 1.50223 4.54552C1.51032 4.68196 1.55546 4.81361 1.63281 4.92631L8.38281 14.6763C8.66256 15.0806 9.33606 15.0806 9.61656 14.6763L16.3666 4.92631C16.4447 4.81385 16.4905 4.68213 16.499 4.54546C16.5076 4.40879 16.4785 4.2724 16.4149 4.1511C16.3514 4.0298 16.2558 3.92824 16.1386 3.85745C16.0214 3.78665 15.887 3.74934 15.7501 3.74956Z"
                            fill="#0F0F0F"
                          />
                        </svg>
                      </button>
                      {mostrarOpciones && (
                        <div className="bottom-filter-new-add">
                          <ul className="ul-filter">
                            <li
                              className="li-filter"
                              onClick={() => seleccionarOpcion("Todo")}
                            >
                              Todo
                            </li>
                            <li
                              className="li-filter"
                              onClick={() => seleccionarOpcion("Mas antiguos")}
                            >
                              Más antiguos
                            </li>
                            <li
                              className="li-filter"
                              onClick={() => seleccionarOpcion("Sobre FISEL")}
                            >
                              Sobre FISEL
                            </li>
                            <li
                              className="li-filter"
                              onClick={() =>
                                seleccionarOpcion("Sobre Afiliados")
                              }
                            >
                              Sobre afiliados
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                    <SearchNews
                      handleChange={handleSearch}
                      news={newsData}
                      selectedOption={opcionSeleccionada}
                    />
                  </div>

                  {isLoading && (
                    <div className="spinner-news">
                      <ClipLoader color="#09265d" />
                    </div>
                  )}
                  <div className="news-card1">
                    {newsData
                      .slice(0, displayedNewsCount)
                      .map((item, index) => (
                        <CardNew value={item} key={index} />
                      ))}
                  </div>
                </div>
              )}

              <div className="view-all-news">
                {newsData.length > displayedNewsCount && (
                  <button
                    className="diary-view-all-news"
                    onClick={handleShowMore}
                  >
                    Ver más
                  </button>
                )}
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};
