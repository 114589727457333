import React from "react";
import "react-quill/dist/quill.snow.css";
import instance from "../../../config/axiosConfig";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import HeaderCard from "../../common/headerCard/HeaderCard";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import toast from "react-hot-toast";
import NewsForm from "./form/NewsForm";

const CreateNewsAdmin = ({ user }) => {
  const navigate = useNavigate();

  const initialValues = {
    title: null,
    subtitle: null,
    body: null,
    isByFisel: true,
    createdBy: user?.id,
    cover: null,
    tags: null,
  };

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await instance.post("news", values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const newsId = response.data.news.id;
      navigate(`/noticias/${newsId}`);
      toast.success(response.data.message);
    } catch (error) {
      console.error("Error al  la noticia:", error);
      toast.error(error.response.data.error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box>
      <HeaderCard
        title="Publica una noticia"
        leftContent={
          <Button
            startIcon={<KeyboardArrowLeftIcon />}
            variant="outlined"
            onClick={() => navigate("/admin/news")}
          >
            Volver
          </Button>
        }
      />
      <NewsForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        label="Publicar"
      />
    </Box>
  );
};

export default CreateNewsAdmin;
