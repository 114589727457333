import React, { useEffect, useRef, useState } from "react";
import "../../../styles/common/ContactPop.css";
import { useLocation } from "react-router-dom";
import instance from "../../../config/axiosConfig";
import toast, { Toaster } from "react-hot-toast";

export const ContactPop = ({ open, setOpen }) => {
  const contactoRef = useRef(null);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    nombre: "",
    email: "",
    asunto: "",
    mensaje: "",
  });
  const [validationStates, setValidationStates] = useState({
    nombre: "sin-validar",
    email: "sin-validar",
    asunto: "sin-validar",
    mensaje: "sin-validar", // Agregamos el estado de validación para el campo mensaje
  });

  useEffect(() => {
    const scrollToContactIfNeeded = () => {
      if (location.pathname !== "/" && contactoRef.current) {
        const contactoSection = contactoRef.current;
        const offsetTop = contactoSection.offsetTop;
        window.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
      }
    };

    scrollToContactIfNeeded();
  }, [location]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    handleValidationState(name, value);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateNombre = (nombre) => {
    return nombre.length > 3 && nombre.length < 40;
  };

  const validateAsunto = (asunto) => {
    return asunto.length > 3 && asunto.length < 40;
  };

  const validateMensaje = (mensaje) => {
    return mensaje.length > 4; // Validamos que el mensaje tenga al menos 5 caracteres
  };

  const handleValidationState = (name, value) => {
    let isValid = true;
    switch (name) {
      case "nombre":
        isValid = validateNombre(value);
        break;
      case "email":
        isValid = validateEmail(value);
        break;
      case "asunto":
        isValid = validateAsunto(value);
        break;
      case "mensaje":
        isValid = validateMensaje(value);
        break;
      default:
        break;
    }
    setValidationStates({
      ...validationStates,
      [name]: isValid ? "valido" : "no-valido",
    });
  };

  // Verifica si todos los campos tienen validación verde
  const isFormValid = () => {
    return (
      validationStates.nombre === "valido" &&
      validationStates.email === "valido" &&
      validationStates.asunto === "valido" &&
      validationStates.mensaje === "valido"
    );
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await instance.post("email/contact", formData);
      setFormData({
        nombre: "",
        email: "",
        asunto: "",
        mensaje: "",
      });
      toast.success("¡Se envió correctamente!");
    } catch (error) {
      console.error("Error:", error);
      toast.error("¡Error al enviar, revisa los campos!");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div ref={contactoRef} className="contact-pop" id="contacto">
      <div className="back-contact-pop">
        {open && (
          <button
            onClick={() => setOpen(false)}
            className="TodoForm-button-pop TodoForm-button--cancel"
            type="button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M18 6L6 18M6 6L18 18"
                stroke="#0F0F0F"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        )}
        <h1 className="title-contact-pop">Contacto</h1>
        <div className="desc-contact-pop">
          <p className="desc-contact-text-pop">
            ¿Realizaste trabajos de instalación? ¿Necesitas alguien que te guíe?
            ¿Necesitas conseguir seguros contra riesgos? ¿Te gustaría unirte al
            mejor equipo de toda América? Envíanos tus datos y nos contactaremos
            contigo.
          </p>
        </div>
        <div className="back-input-contact-pop">
          <input
            type="text"
            name="nombre"
            placeholder="Nombre"
            className={`input-contact-n-pop ${
              validationStates.nombre === "valido"
                ? "green-border"
                : validationStates.nombre === "no-valido"
                ? "red-border"
                : ""
            }`}
            value={formData.nombre}
            onChange={handleChange}
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            className={`input-contact-e-pop ${
              validationStates.email === "valido"
                ? "green-border"
                : validationStates.email === "no-valido"
                ? "red-border"
                : ""
            }`}
            value={formData.email}
            onChange={handleChange}
          />
          <select
            name="asunto"
            className={`input-contact-a-pop ${
              validationStates.asunto === "valido"
                ? "green-border"
                : validationStates.asunto === "no-valido"
                ? "red-border"
                : ""
            }`}
            value={formData.asunto}
            onChange={handleChange}
          >
            <option value="">Asunto</option>
            <option value="Necesito una guía">Necesito una guía</option>
            <option value="Soy principiante">Soy principiante</option>
            <option value="Seg. Riesgo">Seg. contra riesgo</option>
            <option value="Quiero unirme">Quiero unirme</option>
          </select>
        </div>
        <select
          name="asunto"
          className={`input-contact2-pop ${
            validationStates.asunto === "valido"
              ? "green-border"
              : validationStates.asunto === "no-valido"
              ? "red-border"
              : ""
          }`}
          value={formData.asunto}
          onChange={handleChange}
        >
          <option value="">Asunto</option>
          <option value="Necesito una guía">Necesito una guía</option>
          <option value="Soy principiante">Soy principiante</option>
          <option value="Seg. Riesgo">Seg. contra riesgo</option>
          <option value="Quiero unirme">Quiero unirme</option>
        </select>
        <textarea
          name="mensaje"
          placeholder="Escribe un mensaje..."
          className={`textarea-contact-pop ${
            validationStates.mensaje === "valido"
              ? "green-border"
              : validationStates.mensaje === "no-valido"
              ? "red-border"
              : ""
          }`}
          value={formData.mensaje}
          onChange={handleChange}
        ></textarea>

        <div className="back-button-contact-pop">
          <button
            className={`button-contact-pop ${isFormValid() ? "active" : ""}`}
            onClick={handleSubmit}
            disabled={!isFormValid()}
            style={{ backgroundColor: isFormValid() ? "#0400BE" : "" }} // Cambiamos el color de fondo cuando el formulario es válido
          >
            {isLoading ? (
              <span className="text-button-span-pop">Cargando</span>
            ) : (
              <span className="text-button-span-pop">Enviar</span>
            )}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
            >
              <path
                d="M20.5 1.5L13.85 20.5L10.05 11.95L1.5 8.15L20.5 1.5Z"
                stroke="white"
                strokeWidth="2"
                strokeLinejoin="round"
              />
              <path
                d="M20.5 1.5L10.05 11.95"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <Toaster position="top-right" />
        </div>
      </div>
    </div>
  );
};
