import instance from "../../config/axiosConfig";
import {
  setLoadingDiary,
  setAllDiary,
  setLoadingById,
  setDiaryById,
  setLatestDiary,
  setLoadingLatest,
} from "../../redux/slice/diarySlice";

export const allDiary = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoadingDiary(true));
      const response = await instance.get("diary");
      const diary = response.data;

      dispatch(setAllDiary(diary));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoadingDiary(false));
    }
  };
};

export const getDiaryById = (diaryId) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoadingById(true));
      const response = await instance.get(`diary/${diaryId}`);
      const diary = response.data;

      dispatch(setDiaryById(diary));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoadingById(false));
    }
  };
};

export const getLatestDiary = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoadingLatest(true));
      const response = await instance.get(`diary/latest`);
      const diary = response.data;

      dispatch(setLatestDiary(diary));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoadingLatest(false));
    }
  };
};
