import React from "react";
import HeaderCard from "../../common/headerCard/HeaderCard";
import toast from "react-hot-toast";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import instance from "../../../config/axiosConfig";
import PartnerForm from "./form/PartnerForm";
import styled from "styled-components";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const PartnerCreate = ({ user }) => {
  const navigate = useNavigate();

  const initialValues = {
    type: 0,
    logo: null,
    name: null,
    email: null,
    country: null,
    reference: null,
    web: null,
  };

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await instance.post("partner", values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      resetForm();
      setFormKey(Date.now());
      fetchData();
      toast.success(response.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Component>
      <HeaderCard
        title="Nuevo Partner"
        leftContent={
          <Button
            startIcon={<KeyboardArrowLeftIcon />}
            variant="outlined"
            onClick={() => navigate("/admin/partners")}
          >
            Volver
          </Button>
        }
      ></HeaderCard>
      <PartnerForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        label="Nuevo Partner"
      />
    </Component>
  );
};

const Component = styled.div``;

export default PartnerCreate;
