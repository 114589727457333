import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allDiary: null,
  diaryById: null,
  latestDiary: null,
  isLoadingLatest: false,
  isLoading: false,
  isLoadingById: false,
};

const diarySlice = createSlice({
  name: "diary",
  initialState,
  reducers: {
    setAllDiary: (state, action) => {
      state.allDiary = action.payload;
    },
    setDiaryById: (state, action) => {
      state.diaryById = action.payload;
    },
    setLoadingDiary: (state, action) => {
      state.isLoading = action.payload;
    },
    setLoadingById: (state, action) => {
      state.isLoadingById = action.payload;
    },
    setLatestDiary: (state, action) => {
      state.latestDiary = action.payload;
    },
    setLoadingLatest: (state, action) => {
      state.isLoadingLatest = action.payload;
    },
  },
});

export const {
  setAllDiary,
  setDiaryById,
  setLoadingDiary,
  setLoadingById,
  setLatestDiary,
  setLoadingLatest,
} = diarySlice.actions;

export default diarySlice.reducer;
