import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MenuCustom from "../../../common/menu/MenuCustom";
import SchedulColumn from "./SchedulColumn";
import SchedulType from "./SchedulType";
import DateColumn from "../../../common/customTable/DateColumn";
import CustomTable from "../../../common/customTable/CustomTable";
import ModalDelete from "../../../common/modals/ModalDelete";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { allDiary } from "../../../../redux/thunks/diaryFetchThunk";

const ScheduleTable = () => {
  const navigate = useNavigate();
  const [deleteId, setDeleteId] = useState(null);
  const dispatch = useDispatch();
  const fetchData = () => {
    dispatch(allDiary());
  };

  useEffect(() => {
    fetchData();
  }, []);

  const schedules = useSelector((state) => state.diary.allDiary);
  const isLoading = useSelector((state) => state.diary.isLoading);

  const rows = schedules
    ?.map((item) => {
      return item;
    })
    .reverse();

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.8,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "cover",
      headerName: "Agenda",
      renderCell: (params) => <SchedulColumn schedul={params.row} />,
      flex: 1.7,
      minWidth: 200,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "type",
      headerName: "Tipo",
      renderCell: (params) => <SchedulType type={params.value} />,
      flex: 1.5,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "createdAt",
      headerName: "Fecha",
      renderCell: (params) => <DateColumn date={params.value} />,
      flex: 1,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
    },
    {
      headerName: "Menu",
      renderCell: (params) => {
        const options = [
          {
            label: "Ver",
            icon: "view",
            onClick: () => navigate(`/agenda/${params?.id}`),
          },
          {
            label: "Editar",
            icon: "edit",
            onClick: () => navigate(`/admin/diary/${params?.id}/edit`),
          },
          {
            label: "Eliminar",
            icon: "delete",
            onClick: () => setDeleteId(params?.id),
          },
        ];
        return <MenuCustom item={params} options={options} />;
      },
      flex: 0.8,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  return (
    <Box>
      <CustomTable rows={rows} columns={columns} isLoading={isLoading} />
      <ModalDelete
        open={deleteId}
        onClose={() => setDeleteId(null)}
        onFinish={fetchData}
        url={`diary/${deleteId}`}
        body={`Estas seguro que deseas eliminar la agenda con id: ${deleteId}`}
        style={{ maxWidth: "300px" }}
      />
    </Box>
  );
};

const Component = styled.div``;

export default ScheduleTable;
