import React from "react";
import instance from "../../../config/axiosConfig";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import HeaderCard from "../../common/headerCard/HeaderCard";
import ScheduleForm from "./form/ScheduleForm";
import toast, { Toaster } from "react-hot-toast";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const CreateDiaryAdmin = () => {
  const navigate = useNavigate();

  const initialValues = {
    type: "evento",
    isFree: true,
    price: null,
    currency: "MXN",
    start_date: null,
    end_date: null,
    start_hour: null,
    end_hour: null,
    meetings: null,
    duration: null,
    cover: null,
    title: null,
    difficulty: null,
    category: null,
    platform: null,
    detail: null,
    description: null,
  };

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await instance.post("diary", values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const schedulId = response?.data?.diary?.id;
      navigate(`/agenda/${schedulId}`);
      toast.success(response?.data?.message);
    } catch (error) {
      toast.error("Error al crear la agenda!");
      console.error("Error al guardar la agenda:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <HeaderCard
        title="Crear Agenda"
        leftContent={
          <Button
            startIcon={<KeyboardArrowLeftIcon />}
            variant="outlined"
            onClick={() => navigate("/admin/diary")}
          >
            Volver
          </Button>
        }
      />
      <ScheduleForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        label="Crear"
      />
      <Toaster position="top-right" />
    </div>
  );
};

export default CreateDiaryAdmin;
