import React from "react";
import { Box, Modal, Typography } from "@mui/material";
import styled from "styled-components";

const ModalCustom = ({ children, style = {}, open, onClose, title }) => {
  return (
    <StyledModal open={open} onClose={onClose}>
      <Component>
        <Box className="header">
          <Typography>{title}</Typography>
        </Box>
        <Box className="body" style={style}>
          {children}
        </Box>
      </Component>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  overflow: hidden;
  outline: none;
  padding: 10px;
`;

const Component = styled(Box)`
  margin: 0 auto;
  background-color: white;
  border-radius: 10px;
  max-height: 90vh;
  max-width: 600px;
  overflow: hidden;
  overflow-y: auto;
  outline: none;

  .header {
    padding: 15px 20px;
    border-bottom: 1px solid #ccc;
  }
  .body {
    padding: 20px;
  }
`;

export default ModalCustom;
