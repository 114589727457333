import React from "react";
import { Typography, Box, IconButton } from "@mui/material";
import styled, { keyframes } from "styled-components";
import { Bars3Icon } from "@heroicons/react/24/outline";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch } from "react-redux";
import { toggleSidebar } from "../../../redux/slice/sidebarSlice";

function HeaderCard({ title, leftContent }) {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(toggleSidebar());
  };

  return (
    <Component>
      <Box className="component">
        <Box className="title-container">
          <IconButton className="iconButton-header" onClick={handleClick}>
            <Bars3Icon className="icon-header" />
          </IconButton>

          <p className="title">{title}</p>
        </Box>
        <Box>{leftContent}</Box>
      </Box>
    </Component>
  );
}

const slideDown = keyframes`
  from {
    transform: translateY(-50%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const Component = styled.div`
  // animation: ${slideDown} 0.5s ease forwards;

  .component {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 80px;
  }

  .title-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .iconButton-header {
    display: none;
    // padding: 0px;
  }
  .icon-header {
    width: 30px;
    height: 30px;
    margin-top: 7px;
  }

  .title {
    font-size: 30px !important;
    font-weight: 700 !important;
    color: #64748b;
  }

  @media (max-width: 1000px) {
    .component {
      margin-bottom: 60px;
    }
    .title-container {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .iconButton-header {
      display: block;
    }
    .title {
      font-size: 20px !important;
      font-weight: 700 !important;
      color: #474747;
    }
  }

  @media (max-width: 610px) {
    .admin-main {
      padding: 10px;
    }
  }

  @media (max-width: 460px) {
    .title {
      font-size: 16px !important;
      font-weight: 700 !important;
      color: #474747;
    }
  }
`;

export default HeaderCard;
