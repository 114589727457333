import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allUsers: null,
  isLoading: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setAllUser: (state, action) => {
      state.allUsers = action.payload;
    },
    setLoadingUsers: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setAllUser, setLoadingUsers } = userSlice.actions;
export default userSlice.reducer;
