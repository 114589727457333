import React, { useState } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import styled from "styled-components";
import toast, { Toaster } from "react-hot-toast";
import ModalCustom from "./ModalCustom";
import instance from "../../../config/axiosConfig";

const ModalEdit = ({
  children,
  open,
  onClose,
  onFinish,
  url,
  value,
  style,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await instance.put(url, value);
      toast.success("Se edito correctamente");
      onClose();
      onFinish();
    } catch (error) {
      toast.error("Error al editar");
      console.log({ error: error });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalCustom open={open} onClose={onClose} style={style} title="Editar">
      <Component>
        <Box>{children}</Box>
        <Box className="button-container">
          <Button variant="outlined" onClick={handleSubmit}>
            {isLoading ? <CircularProgress size={20} /> : "Editar"}
          </Button>
          <Button variant="outlined" color="error" onClick={onClose}>
            Cancelar
          </Button>
        </Box>
      </Component>
      <Toaster position="top-right" />
    </ModalCustom>
  );
};

const Component = styled.div`
  .title {
    margin-bottom: 20px;
    margin-bottom: 40px;
    text-align: center;
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
`;

export default ModalEdit;
