import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  news: null,
  newsById: null,
  isLoadingById: false,
  isLoading: false,
};

const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    setNews: (state, action) => {
      state.news = action.payload;
    },
    isLoadingNews: (state, action) => {
      state.isLoading = true;
    },
    lodingNewsDone: (state, action) => {
      state.isLoading = false;
    },
    setNewsById: (state, action) => {
      state.newsById = action.payload;
    },
    setIsLodingById: (state, action) => {
      state.isLoadingById = true;
    },
    doneIsLodingById: (state, action) => {
      state.isLoadingById = false;
    },
    removeNewsById: (state, action) => {
      state.news = state.news.filter((item) => item.id !== action.payload);
    },
  },
});

export const {
  setNews,
  isLoadingNews,
  lodingNewsDone,
  setNewsById,
  setIsLodingById,
  doneIsLodingById,
  removeNewsById,
} = newsSlice.actions;

export default newsSlice.reducer;
