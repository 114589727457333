import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NewsColumn from "./NewsColumn";
import DateColumn from "../../../common/customTable/DateColumn";
import ActiveColumn from "../../../common/customTable/ActiveColumn";
import MenuCustom from "../../../common/menu/MenuCustom";
import { Box } from "@mui/material";
import BodyCard from "../../../common/bodyCard/BodyCard";
import CustomTable from "../../../common/customTable/CustomTable";
import ModalDelete from "../../../common/modals/ModalDelete";
import { getAllNews } from "../../../../redux/thunks/newsFetchThunk";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import NewsCreatedBy from "./NewsCreatedBy";

const NewsAdminTable = () => {
  const [deleteId, setDeleteId] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchData = () => {
    dispatch(getAllNews());
  };

  useEffect(() => {
    fetchData();
  }, []);

  const allNews = useSelector((state) => state.news.news);
  const isLoading = useSelector((state) => state.news.isLoading);

  const rows = allNews?.map((news) => {
    return {
      id: news?.id,
      cover: news?.cover,
      title: news?.title,
      subtitle: news?.subtitle,
      isActive: news?.isActive,
      isByFisel: news?.isByFisel ? "Si" : "No",
      createdAt: news?.createdAt,
      createdBy: news?.createdBy,
    };
  });

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.8,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "cover",
      headerName: "Portada",
      renderCell: (params) => <NewsColumn news={params?.row} />,
      flex: 1.7,
      minWidth: 200,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "isByFisel",
      headerName: "Original",
      flex: 1,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "createdAt",
      headerName: "Fecha",
      renderCell: (params) => <DateColumn date={params.value} />,
      flex: 1,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
    },
    {
      field: "createdBy",
      headerName: "Por",
      renderCell: (params) => <NewsCreatedBy by={params.value} />,
      flex: 1,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
    },
    {
      field: "isActive",
      headerName: "Activo",
      renderCell: (params) => <ActiveColumn value={params.value} />,
      flex: 0.8,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      headerName: "Menu",
      renderCell: (params) => {
        const options = [
          {
            label: "Ver",
            onClick: () => navigate(`/noticias/${params?.id}`),
            icon: "view",
          },
          {
            label: "Editar",
            onClick: () => navigate(`/admin/news/${params?.id}/edit`),
            icon: "edit",
          },
          {
            label: "Eliminar",
            onClick: () => setDeleteId(params?.id),
            icon: "delete",
          },
        ];
        return <MenuCustom item={params} options={options} />;
      },
      flex: 0.8,
      minWidth: 100,
      sortable: false,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
  ];
  return (
    <Box>
      <BodyCard style={{ padding: "0" }}>
        <CustomTable rows={rows} columns={columns} isLoading={isLoading} />
      </BodyCard>
      <ModalDelete
        open={deleteId}
        onClose={() => setDeleteId(null)}
        onFinish={fetchData}
        url={`news/${deleteId}`}
        body={`Estas seguro que deseas eliminar la noticia con id: ${deleteId}`}
        style={{ maxWidth: "300px" }}
      />
    </Box>
  );
};

export default NewsAdminTable;
