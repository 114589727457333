import React, { useEffect } from "react";
import "../../styles/others/NewDescription.css";
import { getNewsById, getAllNews } from "../../redux/thunks/newsFetchThunk";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import { NavLink } from "react-router-dom";
import {
  Avatar,
  Chip,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import { upperFirst } from "lodash";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import Share from "../common/share/Share";
import HelmetDetail from "../../utils/HelmetDetail";

export const NewDescription = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    const consult = () => {
      dispatch(getNewsById(id));
      dispatch(getAllNews());
    };

    consult();
  }, []);

  const news = useSelector((state) => state.news.newsById);
  const isLoading = useSelector((state) => state.news.isLoadingById);
  const allNewsNow = useSelector((state) => state.news.news || []);

  const getRandomRelatedNews = () => {
    const randomNews = [];
    const usedIndexes = [];
    while (randomNews.length < 2 && randomNews.length < allNewsNow.length) {
      const randomIndex = Math.floor(Math.random() * allNewsNow.length);
      if (randomIndex !== id && !usedIndexes.includes(randomIndex)) {
        randomNews.push(allNewsNow[randomIndex]);
        usedIndexes.push(randomIndex);
      }
    }
    return randomNews;
  };

  const relatedNews = getRandomRelatedNews();

  const date = moment(news?.date)
    .utc()
    .format("D [de] MMMM [de] YYYY HH:mm[hs]");

  const currentUrl = window.location.href;

  if (isLoading)
    return (
      <div className="spinner-news" style={{ margin: "100px 0" }}>
        <CircularProgress />
      </div>
    );
  return (
    <>
      <HelmetDetail
        tabTitle={`${news?.title} | Fisel Noticias`}
        title={news?.title}
        description={news?.subtitle}
        image={news?.cover}
        type={"article"}
      />
      <div className="back-newid">
        <div className="newid">
          {/* compartir ---------------------------------------------- */}
          <div style={{ position: "relative" }}>
            <div className="relation-new2">
              <Share url={currentUrl} image={news?.cover} title={news?.title} />
            </div>
          </div>

          {/* noticia ------------------------------------------------ */}
          {/* <h1 className="title-newid">Noticias</h1>
          <p className="subtitle-newid">Ultimas novedades de {date}</p> */}

          <div className="column-general">
            <div className="back-column1">
              <img src={news?.cover} className="img-description-new" />
              <h1 className="title-new">
                {news?.title.charAt(0).toUpperCase() + news?.title.slice(1)}
              </h1>
              <p className="sub-new">{date}</p>
              <p className="subtitle-new">{news?.subtitle}</p>

              <div
                style={{
                  marginBottom: "40px",
                }}
              >
                <Divider />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    padding: "20px 0",
                  }}
                >
                  <Avatar
                    src={news?.createdBy?.photo}
                    sx={{ width: 60, height: 60 }}
                  />
                  <div>
                    <Typography
                      style={{ fontsize: "16px", fontWeight: "bold" }}
                    >{`Por ${upperFirst(
                      news?.createdBy?.firstname
                    )} ${upperFirst(news?.createdBy?.lastname)}`}</Typography>
                    <Typography style={{ fontSize: "13px" }}>
                      {news?.createdBy?.description}
                    </Typography>
                  </div>
                </div>
                <Divider />
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: news?.body }}
                className="detail-new-description-body"
              ></div>

              <div style={{ margin: "40px 0" }}>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    Etiquetas
                  </Typography>
                  <LocalOfferIcon fontSize="12px" />
                </div>
                <Divider />
                <div
                  style={{ marginTop: "20px", display: "flex", gap: "10px" }}
                >
                  {news?.tags?.map((e, i) => (
                    <Chip label={e} />
                  ))}
                </div>
              </div>
              <div className="relation-new2-bottom">
                <Share
                  url={currentUrl}
                  image={news?.cover}
                  title={news?.title}
                />
              </div>
            </div>
          </div>

          {/* relacionados ---------------------------------------------- */}
          <div
            style={{ maxWidth: "300px", width: "100%" }}
            className="back-relation-new-right"
          >
            <div className="relation-new">
              <h1 className="title-relation">Relacionados</h1>
              {relatedNews.map((related, index) => (
                <div key={index} style={{ maxWidth: "240px" }}>
                  <div className="back-card-relation">
                    <img
                      src={related.cover}
                      className="img-relation-card"
                      alt={related.title}
                    />
                    <div className="description-date-card">
                      {moment(related.date).utc().format("DD/MM/YY")}
                    </div>
                  </div>
                  <div>
                    <p className="title-relation-card">{related.title}</p>
                    <p className="sub-relation-card">{related.subtitle}</p>
                  </div>
                </div>
              ))}
              <NavLink to="/noticias" className="button-more">
                Ver más
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                >
                  <path
                    d="M13.7686 3.66865C14.0686 3.3687 14.4755 3.2002 14.8998 3.2002C15.3241 3.2002 15.731 3.3687 16.031 3.66865L23.231 10.8687C23.531 11.1687 23.6995 11.5756 23.6995 11.9999C23.6995 12.4241 23.531 12.831 23.231 13.1311L16.031 20.3311C15.7292 20.6225 15.3251 20.7838 14.9056 20.7801C14.486 20.7765 14.0847 20.6082 13.7881 20.3116C13.4914 20.0149 13.3232 19.6136 13.3195 19.1941C13.3159 18.7746 13.4772 18.3704 13.7686 18.0687L18.0998 13.5999H2.8998C2.47546 13.5999 2.06849 13.4313 1.76843 13.1312C1.46838 12.8312 1.2998 12.4242 1.2998 11.9999C1.2998 11.5755 1.46838 11.1685 1.76843 10.8685C2.06849 10.5684 2.47546 10.3999 2.8998 10.3999H18.0998L13.7686 5.93105C13.4687 5.63101 13.3001 5.22412 13.3001 4.79985C13.3001 4.37559 13.4687 3.9687 13.7686 3.66865Z"
                    fill="white"
                  />
                </svg>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
