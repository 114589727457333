import React from "react";
import { Chip } from "@mui/material";
import styled from "styled-components";

const RolColumn = ({ rol }) => {
  return (
    <Component>
      <Chip className={`chip ${rol}`} label={rol} size="small" />
    </Component>
  );
};

const Component = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 100%;

  .chip {
    border-radius: 5px;
    font-weight: 700;
    font-size: 12px;
  }

  .chip.super-admin {
    background-color: #ffd1d1;
    color: #b13e3e;
  }

  .chip.admin {
    background-color: #bef5bd;
    color: #319b40;
  }

  .chip.user {
    background-color: #f5dcbdb8;
    color: #a0762c;
  }
`;

export default RolColumn;
