import React, { useState } from "react";
import "../../../styles/common/Tags.css";
import { Autocomplete, TextField } from "@mui/material";
import { Box } from "@mui/material";

const Tags = ({ onChange, value }) => {
  const handleChangeTag = (e, newValue) => {
    onChange(newValue);
  };

  return (
    <Box>
      <Autocomplete
        multiple
        id="tags-outlined"
        onChange={handleChangeTag}
        options={top100Films}
        value={value}
        getOptionLabel={(option) => option}
        defaultValue={[top100Films[0]]}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label="Seleccione un tema (tag)"
            placeholder="Tags"
          />
        )}
      />
    </Box>
  );
};

export default Tags;

const top100Films = [
  "Fisel",
  "Socios",
  "Partners",
  "Integradores",
  "Electronica",
  "Seguridad",
  "Camaras",
  "Tecnologia",
  "Software",
  "Virtual",
  "Social",
  "Interes",
  "Inteligencia artifical",
  "Eventos",
  "Oportunidad",
  "Deporte",
  "Economia",
  "Politica",
  "Actualidad",
  "Fiesta",
  "Nuevo",
];
