import React, { useEffect, useState } from "react";
import styled from "styled-components";
import HeaderCard from "../../common/headerCard/HeaderCard";
import BodyCard from "../../common/bodyCard/BodyCard";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getAllUsers } from "../../../redux/thunks/userFetchthunk";
import { Box } from "@mui/material";
import UserAdminTable from "./table/UserAdminTable";
import ModalCreate from "./modal/ModalCreate";
import { Toaster } from "react-hot-toast";

const UsersAdmin = ({ userlogged }) => {
  const dispatch = useDispatch();

  const fetchData = () => {
    dispatch(getAllUsers());
  };

  useEffect(() => {
    fetchData();
  }, []);

  const allUsers = useSelector((state) => state.users.allUsers);
  const isLoading = useSelector((state) => state.users.isLoading);
  const [openCreate, setOpenCreate] = useState(false);

  const handleOpenCreate = () => {
    setOpenCreate(true);
  };
  const createButtonActive = userlogged?.rol !== "super-admin";

  return (
    <Component>
      <HeaderCard
        title="Usuarios"
        leftContent={
          <Button
            variant="outlined"
            onClick={handleOpenCreate}
            disabled={createButtonActive}
          >
            Crear un usuario
          </Button>
        }
      />
      <BodyCard style={{ padding: "0" }}>
        <UserAdminTable
          userlogged={userlogged}
          allUsers={allUsers}
          onChange={() => setOpenCreate(true)}
          isLoading={isLoading}
          fetchData={fetchData}
        />
      </BodyCard>
      <ModalCreate
        open={openCreate}
        onClose={() => setOpenCreate(false)}
        onChange={fetchData}
        logout={false}
      />
      <Toaster position="top-right" />
    </Component>
  );
};

const Component = styled.div`
  .circular-progress-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 60px 0px;
  }
`;

export default UsersAdmin;
