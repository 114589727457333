import React from "react";
import { NewDescription } from "./../components/new/NewDescription";
import Navbar from "../components/common/navbar/Navbar";
import { Footer } from "../components/home/Footer";

export const NewId = () => {
  return (
    <div>
      <Navbar />
      <NewDescription />
      <Footer />
    </div>
  );
};
