import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  CircularProgress,
  Typography,
  IconButton,
  Avatar,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import HeaderCard from "../../common/headerCard/HeaderCard";
import BodyCard from "../../common/bodyCard/BodyCard";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { getLogos } from "../../../redux/thunks/logoFetchThunk";
import moment from "moment";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useNavigate } from "react-router-dom";
import ModalDelete from "../../common/modals/ModalDelete";
import ModalEdit from "../../common/modals/ModalEdit";

const PartnersAdmin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchData = async () => {
    dispatch(getLogos());
  };

  const [formKey, setFormKey] = useState(Date.now());

  return (
    <Component>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Fisel | Dashboard - Logos`}</title>
      </Helmet>

      <HeaderCard
        title="Logos"
        leftContent={
          <Button
            variant="outlined"
            endIcon={<KeyboardArrowRightIcon />}
            onClick={() => navigate("/admin/partners/create")}
          >
            Nuevo Partner
          </Button>
        }
      />
      <Box className="body">
        {/* Listado de logos -------------------------------------------- */}
        <BodyCard title="Listado" style={{ padding: "0" }}>
          <LogoList />
        </BodyCard>
      </Box>
      <Toaster position="top-right" />
    </Component>
  );
};

const LogoList = () => {
  const dispatch = useDispatch();

  const fetchData = async () => {
    dispatch(getLogos());
  };

  useEffect(() => {
    fetchData();
  }, []);

  const logos = useSelector((state) => state.logos.allLogos);
  const isLoading = useSelector((state) => state.logos.isLoading);

  const rows = logos
    ?.map((logo) => {
      return {
        id: logo?.id,
        image: logo?.url,
        reference: logo?.reference,
        isActive: logo?.isActive,
        type: logo?.type === 0 ? "Socio" : "Aliado",
        createdAt: moment(logo?.createdAt).format("DD-MM-YYYY"),
      };
    })
    .reverse();

  const [deleteId, setDeleteId] = useState(null);
  const [logoEdit, setLogoEdit] = useState(null);

  const handleCloseDelete = () => {
    setDeleteId(null);
  };
  const handleCloseEdit = () => {
    setLogoEdit(null);
  };

  const ActionButtons = (logo) => {
    const deleteValue = logo.logo.id;
    const editValue = logo.logo.row;

    return (
      <Box>
        <IconButton onClick={() => setLogoEdit(editValue)}>
          <EditIcon />
        </IconButton>
        <IconButton>
          <DeleteIcon color="error" onClick={() => setDeleteId(deleteValue)} />
        </IconButton>
      </Box>
    );
  };

  const columns = [
    {
      field: "isActive",
      headerName: "Activo",
      renderCell: (params) => {
        return (
          <Box className="box-container">
            {params?.value ? (
              <CheckIcon color="success" />
            ) : (
              <CloseIcon color="error" />
            )}
          </Box>
        );
      },
      flex: 0.8,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "createdAt",
      headerName: "Creado",
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "type",
      headerName: "Tipo",
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "image",
      headerName: "Imagen",
      renderCell: (params) => (
        <Box className="avatar-container">
          <Avatar src={params.value} alt="preview" variant="rounded" />
        </Box>
      ),
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "reference",
      headerName: "Referencia",
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      headerName: "Acciones",
      renderCell: (params) => <ActionButtons logo={params} />,
      flex: 1,
      sortable: false,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
    },
  ];

  const handleEditReference = (event) => {
    const value = event.target.value;
    setLogoEdit({
      ...logoEdit,
      reference: value,
    });
  };

  const handleEditActive = (event) => {
    const value = event.target.checked;
    setLogoEdit({
      ...logoEdit,
      isActive: value,
    });
  };

  const handleEditType = (_event, value) => {
    setLogoEdit({
      ...logoEdit,
      type: value,
    });
  };

  return (
    <Box>
      <ModalDelete
        open={deleteId}
        onClose={handleCloseDelete}
        onFinish={fetchData}
        url={`logo/${deleteId}`}
        title={`Estas seguro que deseas eliminar el logo con id: "${deleteId}"`}
        style={{ maxWidth: "300px" }}
      />

      <ModalEdit
        open={logoEdit}
        onClose={handleCloseEdit}
        onFinish={fetchData}
        url={`logo/${logoEdit?.id}`}
        value={logoEdit}
      >
        <EditComponent>
          <Typography className="edit-title">{`Estas editando el logo con id: "${logoEdit?.id}"`}</Typography>
          <FormControlLabel
            control={<Switch checked={logoEdit?.isActive} />}
            label="logo activo?"
            className="switch"
            onChange={handleEditActive}
          />
          <Box className="button-gruop">
            <Typography className="label">Tipo</Typography>
            <ToggleButtonGroup
              color="primary"
              value={logoEdit?.type}
              exclusive
              onChange={handleEditType}
              aria-label="Platform"
            >
              <ToggleButton value={0}>Socios Integradores</ToggleButton>
              <ToggleButton value={1}>Aliados Corporativos</ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <TextField
            label="Referencia"
            fullWidth
            value={logoEdit?.reference}
            onChange={handleEditReference}
            className="edit-reference"
          />
        </EditComponent>
      </ModalEdit>

      {isLoading && (
        <CircularProgressContainer>
          <CircularProgress />
        </CircularProgressContainer>
      )}
      {!isLoading && (
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row"
          }
        />
      )}
    </Box>
  );
};

const CircularProgressContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0px 80px 0px;
`;

const Component = styled.div`
  .body {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .switch {
    margin: 20px 0;
  }

  .label {
    font-size: 14px;
    color: #0009;
    margin-bottom: 8px;
  }

  .button-gruop {
    margin-bottom: 20px;
  }

  .avatar-container {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
  }

  .box-container {
    display: flex;
    align-items: center;
    height: 100%;
  }
`;

const EditComponent = styled.div`
  .edit-title {
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
  }

  .edit-reference {
    margin: 20px 0;
  }

  .label {
    font-size: 14px;
    color: #0009;
    margin-bottom: 8px;
    margin-top: 20px;
  }

  .button-gruop {
    margin-bottom: 20px;
  }
`;

export default PartnersAdmin;
