import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import HeaderCard from "../../common/headerCard/HeaderCard";
import { Button } from "@mui/material";
import BodyCard from "../../common/bodyCard/BodyCard";
import { Toaster } from "react-hot-toast";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ScheduleTable from "./table/ScheduleAdminTable";

const DiaryAdmin = () => {
  const navigate = useNavigate();
  return (
    <Component>
      <HeaderCard
        title="Agenda"
        leftContent={
          <Button
            onClick={() => {
              navigate("/admin/diary/create");
            }}
            variant="outlined"
            endIcon={<KeyboardArrowRightIcon />}
          >
            Crear agenda
          </Button>
        }
      />
      <BodyCard style={{ padding: "0" }}>
        <ScheduleTable />
      </BodyCard>
      <Toaster position="top-right" />
    </Component>
  );
};

const Component = styled.div``;

export default DiaryAdmin;
