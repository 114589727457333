import React, { useState, useEffect } from "react";
import "../../styles/others/Testimonials.css";
import mexicoFlag from "../../assets/testimonialFlags/mexico.png";
import chileFlag from "../../assets/testimonialFlags/chile.png";
import panamaFlag from "../../assets/testimonialFlags/panama.png";
import ecuadorFlag from "../../assets/testimonialFlags/ecuador.png";
import { testimonials as data } from "./homeData/data.js";

const Testimonials = () => {
  const [carouselIndex, setCarouselIndex] = useState(0);

  const flags = {
    Panamá: panamaFlag,
    México: mexicoFlag,
    Ecuador: ecuadorFlag,
    Chile: chileFlag,
  };

  const handleNext = () => {
    if (carouselIndex < data.length - 1) {
      setCarouselIndex(carouselIndex + 1);
    } else {
      setCarouselIndex(0);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(handleNext, 10000);
    return () => clearInterval(intervalId);
  }, [carouselIndex, data.length]);

  const currentData = data[carouselIndex];

  return (
    <div className="testimonial-component">
      <h2>Testimonios</h2>

      <div className="back-testimonials">
        <div className="testimonials-container">
          <div className="flag-container">
            <img src={flags[currentData.userCountry]} alt={data.userCountry} />
          </div>

          {/* ------- text ------- */}
          <div className="testimonial-info">
            <h2>{currentData.userName}</h2>
            <p>{currentData.UserCategory}</p>
            <p className="testimonial-text">{currentData.userTestimonial}</p>
            <div className="flag-container-2">
              <img src={flags[currentData.userCountry]} alt={data.userCountry} />
            </div>
            <span>{currentData.userCountry}</span>
          </div>
        </div>
      </div>
      <div className="dots-container">
        {data.map((item, index) => (
          <button
            onClick={() => setCarouselIndex(index)}
            key={index}
            className={
              index === carouselIndex ? "carousel-dots active" : "carousel-dots"
            }
          ></button>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
