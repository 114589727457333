import React from "react";
import Lion from "../../assets/leon.png";
import LionResponsive from "../../assets/leon-responsive.png";
import "../../styles/others/Header.css";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <>
    <div className="back-header">
      <div className="column-left-header">
        <div className="div-h1-header">
          <h1 className="h1-header">
            Federación de Integradores<br/> en Seguridad Electrónica.
          </h1>
        </div>
        <div className="div-h2-header">
          <h2 className="h2-header">
          La red de Integradores Lideres más grande e importante.
          </h2>
        </div>
        <div className="div-h3-header">
          <h3 className="h3-header">
          Conoce todos los beneficios, para crecer juntos.
          </h3>
        </div>
        
        <Link to="/planes" className="bottom-header">Ver planes de Afiliados</Link>
        
      </div>
      <div className="column-right-header">
        <img className="img-header" src={Lion} />
        <img className="img-header2" src={LionResponsive} />
      </div>
    </div>
    <div className="div-h3-header2">
    <h2 className="h2-header2">
            La red de Integradores Lideres<br/> más grande e importante.
          </h2>
    <h3 className="h3-header2">
            Conoce todos los beneficios, para crecer juntos. 
          </h3>
    </div>
        
    <Link to="/planes" className="bottom-header2">Planes de Afiliados</Link>
    </>
  );
};

export default Header;
