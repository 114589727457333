import React from "react";
import Afiliado1 from "../../assets/partners/Afiliado-1.png";
import Afiliado2 from "../../assets/partners/Afiliado-2.png";
import Afiliado3 from "../../assets/partners/Afiliado-3.png";
import Afiliado4 from "../../assets/partners/Afiliado-4.png";
import Afiliado5 from "../../assets/partners/Afiliado-5.png";
import BackParner from "../../assets/back-partner.png";
import "../../styles/others/Partners.css";

//SE NOS VAN A MOSTRAR LOS ALIADOS EN ESTA SEGUIDILLA DE IMAGENES
const Partners = () => {
  const row = [
    Afiliado1,
    Afiliado2,
    Afiliado3,
    Afiliado4,
    Afiliado2,
    Afiliado5,
  ];

  return (
    <div className="back-partner">
      <h3 className="h3-description4">Nuestras fuertes Alianzas</h3>
      <div className="card-description-into4">
        <div>
          <p className="card3-text-description">
            “El secreto del verdadero éxito radica en asociarse con otros que
            también tengan el mismo sueño, nada es imposible para un grupo que
            lucha con empeño”
          </p>
        </div>
      </div>

      <div className="back-partner-title">
        <h1 className="partner-title">Socios Integradores</h1>
      </div>
      {/*Recorre los logos y los muestra*/}
      <div className="logos">
        <div className="logos-slide">
          <>
            {row.map((item, index) => (
              <img key={index} src={item} alt={`Logo ${index}`} />
            ))}
          </>
        </div>
        <div className="logos-slide">
          <>
            {row.map((item, index) => (
              <img key={index} src={item} alt={`Logo ${index}`} />
            ))}
          </>
        </div>
      </div>
      <img className="img-partner" src={BackParner}/>
    </div>
  );
};

export default Partners;
