import { Box, Button, Chip, Typography } from "@mui/material";
import React from "react";
import BodyCard from "../common/bodyCard/BodyCard";
import styled from "styled-components";
import CustomAccordion from "../common/cutomAccordion/CustomAccordion";
import SendIcon from "@mui/icons-material/Send";
import "moment/locale/es";
import moment from "moment";
import SchedulType from "../admin/Diary/table/SchedulType";
import { NavLink, useNavigate } from "react-router-dom";

moment.locale("es");

const ScheduleDetail = ({ schedul }) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("https://wa.link/11mq3i");
  };

  const type = schedul?.type;

  const formatNumber = (value) => {
    return new Intl.NumberFormat("es-MX").format(value);
  };

  const formatNewDate = (date) => {
    return moment(date).utc().format("D [de] MMMM");
  };

  const formatHour = (hour) => {
    return moment(hour).format("HH:mm");
  };

  const startDate = `${formatNewDate(schedul?.start_date)} `;
  const endDate = `${formatNewDate(schedul?.start_date)} `;
  const fecha = schedul?.endDate ? `${startDate} al ${endDate}` : startDate;

  const hours =
    type === "curso"
      ? `De ${formatHour(schedul?.start_hour)} a ${formatHour(
          schedul?.end_hour
        )}`
      : `a las ${formatHour(schedul?.start_hour)}`;

  const ClassOrEvent = type === "curso" ? "Clase" : "Evento";

  return (
    <Component>
      <BodyCard>
        <Box className="container">
          <Box className="box-left">
            <Box className="box-detail">
              <Typography className="detail">{schedul?.detail}</Typography>
              <Box className="box-category-level">
                <Typography className="title-left-type">
                  {type === "evento" ? "Categoria" : "Dificultad"}
                </Typography>
                <Typography className="left-type">
                  {type === "evento" ? schedul?.category : schedul?.difficulty}
                </Typography>
              </Box>
            </Box>

            <Box className="box-price">
              <Typography className="cost-label">costo:</Typography>
              {schedul?.isFree === true ? (
                <Typography className="price free">Gratis</Typography>
              ) : (
                <>
                  <Typography className="price">{`${
                    schedul?.currency
                  } ${formatNumber(schedul?.price)}`}</Typography>
                </>
              )}
            </Box>
          </Box>

          {/* --------------------------------------------------------------- */}
          <Box>
            <Box className="background-course-detail">
              <Box
                className="box-cover"
                style={{ backgroundImage: `url(${schedul?.cover})` }}
              >
                <Box className="box-type">
                  <SchedulType type={type} />
                </Box>
                <Box className="box-title">
                  <Typography className="title">{schedul?.title}</Typography>
                </Box>
              </Box>

              {/* -------------------- fecha y hora -------------------- */}
              <Box className="box-date">
                <Box className="container-de-fechas">
                  <Box>
                    <Typography className="label">Fecha: </Typography>
                    <Typography className="dato">{fecha}</Typography>
                  </Box>

                  <Box className="box-fechas">
                    <Typography className="label">
                      Modalidad y horario:
                    </Typography>
                    <Typography className="dato">{`${ClassOrEvent} ${schedul?.platform} ${hours}`}</Typography>
                  </Box>

                  {type === "curso" && (
                    <Box className="box-meetings">
                      <Typography className="label">Clases: </Typography>
                      <Typography className="dato">
                        {schedul?.meetings}
                      </Typography>
                    </Box>
                  )}
                </Box>

                <Box className="box-button">
                  <Button className="button-asesor" endIcon={<SendIcon />}>
                    <NavLink
                      className="button-asesoramiento"
                      to="https://wa.link/11mq3i"
                      target="_blank"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      Pedir asesoramiento
                    </NavLink>
                  </Button>
                </Box>
              </Box>
            </Box>

            {/* ----------------------------------------------------------------- */}
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: schedul?.description }}
            ></div>

            <Box className="divider"></Box>

            <CustomAccordion />
          </Box>
        </Box>
      </BodyCard>
    </Component>
  );
};

const Component = styled.div`
  .container {
    display: flex;
    // flex-direction: column;
    justify-content: space-between;
    gap: 30px;
  }

  .box-left {
    min-width: 250px;
    width: 250px;
  }
  .box-detail {
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 15px;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .detail {
    font-size: 15px;
    color: #333333;
  }
  .box-category-level {
    border: 1px solid #aeaeae;
    padding: 10px;
    border-radius: 10px;
  }
  .title-left-type {
    color: gray;
    font-size: 13px;
  }
  .left-type {
    color: #5a5a5a;
    font-size: 20px;
    font-weight: 700;
  }

  .box-price {
    margin-top: 20px;
    background-color: #f8f8f8;
    padding: 10px 20px;
    border-radius: 10px;
  }
  .cost-label {
    font-size: 14px;
    color: gray;
  }
  .price {
    font-size: 24px;
    font-weight: 700;
    color: #13b53c;
  }

  .price.free {
    font-size: 24px;
    font-weight: 700;
    color: #13b53c;
  }

  //   ---------------------------------------------------------------

  .background-course-detail {
    background-color: #f8f8f8;
    padding-bottom: 30px;
    border-radius: 15px;
  }

  .box-cover {
    width: 100%;
    height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 15px;
    background-image: linear-gradient(blue, red);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: space-between;
    align-items: flex-end;
    padding: 20px;
    position: relative;

    &::before {
      content: "";
      position: absolute;

      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background: linear-gradient(
          0deg,
          rgb(24, 27, 50) 0%,
          rgba(24, 27, 50, 0) 100%
        )
        0% 0% repeat scroll rgba(0, 0, 0, 0);
      z-index: 0;
    }
  }
  .cover {
    border-radius: 10px;
  }
  .box-title {
    position: relative;
    z-index: 1;
    text-align: start;
    width: 100%;
  }
  .title {
    color: white;
    font-size: 25px;
  }

  .box-date {
    padding: 20px 0 0px 20px;
  }

  .container-de-fechas {
    display: flex;
    gap: 20px;
    margin-right: 20px;
  }
  .box-fechas {
  }
  .label {
    font-size: 13px;
    color: gray;
    font-weight: 700;
  }

  .dato {
  }

  .box-button {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }

  .button-asesor {
    background-color: #0400be;
    border-radius: 20px 0 0 20px;
    padding: 10px 20px;
    color: white;
  }
  .button-asesor:hover {
    background-color: #0400be;
  }

  .divider {
    border-top: 1px dashed #ccc;
    margin: 80px 0;
  }

  .description {
    margin: 20px 0;
  }

  @media (max-width: 750px) {
    .container {
      flex-direction: column-reverse;
    }
  }
`;

export default ScheduleDetail;
