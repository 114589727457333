import React, { useState } from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
} from "react-share";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import XIcon from "@mui/icons-material/X";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Button, IconButton, Tooltip } from "@mui/material";

const Share = ({ url, image, title }) => {
  const example = "http://github.com";
  const [copied, setCopied] = useState(false);
  const currentUrl = window.location.href

  const copyUrl = () => {
    navigator.clipboard.writeText(currentUrl);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const shareData = {
    title: title,
    text: 'probando',
    url: example
  }

  const shareAcross = async(content) => {
    try {
      await navigator.share(shareData)
    } catch (error) {
      console.log(error)
    }
  }


  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>

      <div style={{ width: "24px" }}>
        <Tooltip
          open={copied}
          title="copiado"
          placement="right"
          arrow
          PopperProps={{
            disablePortal: true,
          }}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <button
            onClick={copyUrl}
            style={{
              width: "24px",
              border: "none",
              outline: "none",
              cursor: "pointer",
              backgroundColor: "transparent",
            }}
          >
            <ContentCopyIcon />
          </button>
        </Tooltip>
      </div>

      <div>
        <FacebookShareButton url={currentUrl} image={image} title={title}>
          <FacebookIcon />
        </FacebookShareButton>
      </div>

      <div>
        <WhatsappShareButton url={currentUrl} title={title} image={image}>
          <WhatsAppIcon />
        </WhatsappShareButton>
      </div>

      <div>
        <LinkedinShareButton url={currentUrl} title={title} image={image}>
          <LinkedInIcon />
        </LinkedinShareButton>
      </div>

      <div>
        <EmailShareButton url={currentUrl} title={title} image={image}>
          <EmailIcon />
        </EmailShareButton>
      </div>

      <div>
        <TwitterShareButton url={currentUrl} title={title} image={image}>
          <XIcon />
        </TwitterShareButton>
      </div>
    </div>
  );
};

export default Share;
