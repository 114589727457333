import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import RippleCircle from "../../../common/ripple/RippleCircle";
import { useSelector } from "react-redux";

const AvatarNameColumn = ({ user }) => {
  const userLogged = useSelector((state) => state.logged.user);
  return (
    <Component>
      <Avatar src={user?.photo} />
      <Box className="container-data">
        <Box className="box-name">
          <Typography className="name">{`${user?.name}`}</Typography>
          {user?.id === userLogged?.id && (
            <RippleCircle style={{ marginTop: "2px" }} />
          )}
        </Box>
        <Typography className="email">{`${user?.email}`}</Typography>
      </Box>
    </Component>
  );
};

const Component = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 100%;

  .container-data {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .box-name {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .name {
    font-size: 13px;
  }
  .email {
    font-size: 13px;
    color: gray;
    text-overflow: ellipsis;
  }
`;

export default AvatarNameColumn;
