import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';

const TableWithDraggableRows = () => {
  const [rows, setRows] = useState([
    { id: '1', estado: 'Estado 1', imagen: 'imagen1.jpg', tipoArchivo: 'Tipo 1', hora: '10:00', creado: 'Creado 1' },
    { id: '2', estado: 'Estado 2', imagen: 'imagen2.jpg', tipoArchivo: 'Tipo 2', hora: '11:00', creado: 'Creado 2' },
    { id: '3', estado: 'Estado 3', imagen: 'imagen3.jpg', tipoArchivo: 'Tipo 3', hora: '12:00', creado: 'Creado 3' },
  ]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(rows);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setRows(items);
  };

  const moveRow = (dragIndex, hoverIndex) => {
    const draggedRow = rows[dragIndex];
    const newRows = [...rows];
    newRows.splice(dragIndex, 1);
    newRows.splice(hoverIndex, 0, draggedRow);
    setRows(newRows);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="rows">
        {(provided) => (
          <table
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{ width: '100%', borderCollapse: 'collapse' }}
          >
            <thead>
              <tr>
                <th>Estado</th>
                <th>Imagen</th>
                <th>Tipo de archivo</th>
                <th>Hora</th>
                <th>Creado</th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => (
                <Draggable key={row.id} draggableId={row.id} index={index}>
                  {(provided) => (
                    <tr
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={{ ...provided.draggableProps.style }}
                    >
                      <td>{row.estado}</td>
                      <td>{row.imagen}</td>
                      <td>{row.tipoArchivo}</td>
                      <td>{row.hora}</td>
                      <td>{row.creado}</td>
                      <td>
                        <div {...provided.dragHandleProps}>
                          {index !== 0 && (
                            <button
                              onClick={() => moveRow(index, index - 1)}
                              style={{ cursor: 'pointer' }}
                            >
                              <ArrowUpward />
                            </button>
                          )}
                          {index !== rows.length - 1 && (
                            <button
                              onClick={() => moveRow(index, index + 1)}
                              style={{ cursor: 'pointer' }}
                            >
                              <ArrowDownward />
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </tbody>
          </table>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default TableWithDraggableRows;