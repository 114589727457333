import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";

export const CardScheduls = ({ diary }) => {
  const start_date = moment(diary?.start_date).format("DD-MM-YYYY");

  return (
    <Link to={`/agenda/${diary?.id}`} className="schedul-card">
      {/* imagen y chip ----------------------- */}
      <div
        style={{
          width: "100%",
          height: "150px",
          backgroundImage: `url(${diary?.cover})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          padding: "20px",
          borderRadius: "20px",
        }}
      >
        <span
          className={
            diary?.type === "evento"
              ? "chip-agenda evento"
              : "chip-agenda curso"
          }
        >{`${diary?.type}`}</span>
      </div>
      {/* cuerpo de la card ------------------- */}
      <div className="body-agenda">
        <p class="title-agenda">{diary?.title}</p>
        <div className="desc-agenda">
          <p className="agenda-medio">{`Medio: ${diary?.platform}`}</p>
          <p className="agenda-medio">{`Inico: ${start_date}`}</p>

          <div className="boton-agenda">
            {/* {diary?.isFree ? <span className="agenda-price-gratis">Gratis</span> : <span>{diary?.price}</span>} */}
            <span>Ver detalles</span>
            <KeyboardArrowRightOutlinedIcon />
          </div>
        </div>
      </div>
    </Link>
  );
};
