import React, { useState } from "react";
import "../../styles/others/CardNew.css";
import { Link } from "react-router-dom";

//FALTA LOGICA

const CardNew = ({ value }) => {
  const [copied, setCopied] = useState(false);
  const fecha = new Date(value?.createdAt);

  const fechaFormateada = fecha.toLocaleDateString("es-ES", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  });

  const copyToClipboardNew = () => {
    const urlToCopy = window.location.href;
    navigator.clipboard.writeText(urlToCopy).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <Link to={`/noticias/${value?.id}`} className="back-new-card">
      {/* <div className="back-new-card"> */}
      <div
        style={{
          width: "100%",
          height: "200px",
          backgroundImage: `url(${value?.cover})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          // borderRadius: "0px 0px 20px 20px"
        }}
      ></div>
      <p className="new-date-responsive">{fechaFormateada}</p>

      <div style={{ padding: "0px 15px" }}>
        <div className="new-card">
          <p className="back-new-title">
            {value?.title.charAt(0).toUpperCase() + value?.title.slice(1)}
          </p>
        </div>
        <p className="intro-new-card">
          {value?.subtitle.charAt(0).toUpperCase() + value?.subtitle.slice(1)}
        </p>
      </div>
      {/* </div> */}
    </Link>
  );
};

export default CardNew;
