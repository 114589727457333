import React, { useEffect } from "react";
import Navbar from "../common/navbar/Navbar";
import HelmetDetail from "../../utils/HelmetDetail";
import { useDispatch } from "react-redux";
import { getDiaryById } from "../../redux/thunks/diaryFetchThunk";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Footer } from "../home/Footer";
import ScheduleDetail from "./ScheduleDetail";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";

const ScheduleId = () => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const fetchData = () => {
    dispatch(getDiaryById(id));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const schedule = useSelector((state) => state.diary.diaryById);
  const isloading = useSelector((state) => state.diary.isLoadingById);

  return (
    <ScheduleComponent>
      <HelmetDetail
        tabTitle={`${schedule?.title} | Fisel Agenda`}
        tab={schedule?.title}
        description={schedule?.description}
        image={schedule?.cover}
        type="article"
      />
      <Navbar />
      <div className="container">
        {isloading && <CircularProgress />}
        {!isloading && <ScheduleDetail schedul={schedule} />}
      </div>
      <Footer />
    </ScheduleComponent>
  );
};

const ScheduleComponent = styled.div`
  .container {
    max-width: 1590px;
    margin: 0px auto;
    padding: 0px 20px;
  }
`;

export default ScheduleId;
