import instance from "../../config/axiosConfig";
import { setAllBanners, setLoadingBanner } from "../../redux/slice/bannerSlice";

export const getBanners = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoadingBanner(true));
      const response = await instance.get("/banner");
      const banners = response.data;
      dispatch(setAllBanners(banners));
    } catch (error) {
      console.error({ error });
    } finally {
      dispatch(setLoadingBanner(false));
    }
  };
};
