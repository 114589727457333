import React, { useEffect } from "react";
import HeaderCard from "../../common/headerCard/HeaderCard";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Box, Button } from "@mui/material";
import NewsForm from "./form/NewsForm";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import instance from "../../../config/axiosConfig";
import { useDispatch } from "react-redux";
import { getNewsById } from "../../../redux/thunks/newsFetchThunk";

const EditNewsAdmin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    const consult = () => {
      dispatch(getNewsById(id));
    };

    consult();
  }, []);

  const news = useSelector((state) => state.news.newsById);
  const isLoading = useSelector((state) => state.news.isLoadingById);

  const initialValues = {
    title: news?.title,
    subtitle: news?.subtitle,
    body: news?.body,
    isByFisel: news?.isByFisel,
    cover: news?.cover,
    tags: news?.tags,
  };

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const headers = { "Content-Type": "multipart/form-data" };

      const response = await instance.put(`news/${news?.id}`, values, {
        headers,
      });
      resetForm();
      navigate(`/admin/news`);
      toast.success(response.data.message);
    } catch (error) {
      console.error("Error al  la noticia:", error);
      toast.error(error.response.data.error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box>
      <HeaderCard
        title="Editar Noticia"
        leftContent={
          <Button
            startIcon={<KeyboardArrowLeftIcon />}
            variant="outlined"
            onClick={() => navigate("/admin/news")}
          >
            Volver
          </Button>
        }
      />

      <NewsForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        label="Editar"
        loading={isLoading}
      />
    </Box>
  );
};

export default EditNewsAdmin;
