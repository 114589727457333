import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allBanners: null,
    isLoading: false,
}

const bannerSlice = createSlice({
    name: "banner",
    initialState,
    reducers: {
        setAllBanners: (state, action) => {
            state.allBanners = action.payload;
        },
        setLoadingBanner: (state, action) => {
            state.isLoading = action.payload
        }
    }
})

export const {setAllBanners, setLoadingBanner} = bannerSlice.actions
export default bannerSlice.reducer
