import React from "react";
import styled, { keyframes } from "styled-components";

const RippleCircle = ({ style }) => {
  return <Component style={style} />;
};

const ripple = keyframes`
  0% {
    transform: scale(.8);
    opacity: 1;
  }
  100% {
    transform: scale(2.4);
    opacity: 0;
  }
`;
const Component = styled.div`
  position: relative;
  width: 10px;
  height: 10px;
  background-color: #44b700;
  border-radius: 50%;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation: ${ripple} 1.2s infinite ease-in-out;
    border: 1px solid #44b700;
  }
`;

export default RippleCircle;
