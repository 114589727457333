import React from "react";
import SideBar from "../home/SideBar";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { toggleSidebar } from "../../redux/slice/sidebarSlice";
import { Backdrop, Box } from "@mui/material";

const AdminLayout = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.logged.user);
  const isSidebarOpen = useSelector((state) => state.sidebar.isVisible);

  const handleBackdropClick = () => {
    if (isSidebarOpen) {
      dispatch(toggleSidebar()); // Dispatch the action to close sidebar
    }
  };

  return (
    <Component>
      <div className="admin-layaout">
        <Box
          className={
            isSidebarOpen ? "sidebar-container open" : "sidebar-container"
          }
        >
          <SideBar user={user} />
        </Box>

        <main className="admin-main">
          <Outlet />
        </main>
      </div>

      {isSidebarOpen && <Backdrop open={true} onClick={handleBackdropClick} />}
    </Component>
  );
};

const Component = styled.div`
  .admin-layaout {
    display: flex;
  }

  .sidebar-container {
    position: relative;
    transition: all 0.3s ease;
  }

  .admin-main {
    position: relative;
    padding: 30px;
    margin: 0 auto;
    max-width: 1100px;
    width: 100%;
  }

  @media (max-width: 1000px) {
    .admin-layaout {
      flex-direction: column;
    }

    .sidebar-container {
      opacity: 0;
      visibility: hidden;
      position: fixed;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 10;
      transform: translateX(-40%);
      transition: all 0.3s ease;
    }

    .sidebar-container.open {
      // display: block;
      // transform: translateX(0);
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }

  @media (max-width: 610px) {
    .admin-main {
      padding: 10px;
    }
  }
`;

export default AdminLayout;
