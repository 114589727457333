import React, { useState, useEffect } from "react";
import "../../styles/others/Diary.css";
import { NavLink, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { getLatestDiary } from "../../redux/thunks/diaryFetchThunk";
import moment from "moment";
// import { allDiary } from "../redux/thunks/diaryFetchThunk";

const Diary = () => {
  const dispatch = useDispatch();

  const fetchData = async () => {
    dispatch(getLatestDiary());
  };

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const diary = useSelector((state) => state.diary.latestDiary);

  const handleNavigate = () => {
    navigate(`/agenda/${diary?.id}`);
  };

  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  const type = diary?.type;
  const typeLabel = `${type} ${diary?.platform}`;

  const startDate = moment(diary?.start_date).utc().format("DD/MM/YYYY");

  return (
    <div>
      <h2 className="title-diary">Agenda</h2>
      <p className="desc-diary">
        Revisa nuestra agenda y aprovecha las últimas novedades de este año.
      </p>
      <div className="diary-component">
        {/* -------------------------------------------------------------------------- */}
        <div className="back-diary">
          <div className="diary">
            <div>
              <span className="diary-event">Próximo evento</span>
              <img src={diary?.cover} alt />
            </div>
            <div className="diary-info">
              <div className="diary-info-content">
                <h2 className="responsive-text-diary">{type}</h2>
                <h3 className="responsive-text-intro">{diary?.title}</h3>
                <p className="responsive-text-type">{typeLabel}</p>
              </div>
              <div className="back-diary-view">
                <span className="text-data-diary">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <path
                      d="M7.2002 4.5H8H16H16.8002C17.9203 4.5 18.4796 4.5 18.9074 4.71799C19.2837 4.90973 19.5905 5.21547 19.7822 5.5918C20 6.0192 20 6.57899 20 7.69691V8.5V17.3036C20 18.4215 20 18.9805 19.7822 19.4079C19.5905 19.7842 19.2837 20.0905 18.9074 20.2822C18.48 20.5 17.921 20.5 16.8031 20.5H7.19691C6.07899 20.5 5.5192 20.5 5.0918 20.2822C4.71547 20.0905 4.40973 19.7842 4.21799 19.4079C4 18.9801 4 18.4203 4 17.3002V8.5V7.7002C4 6.58009 4 6.01962 4.21799 5.5918C4.40973 5.21547 4.71547 4.90973 5.0918 4.71799C5.51962 4.5 6.08009 4.5 7.2002 4.5Z"
                      fill="white"
                    />
                    <path
                      d="M8 4.5H7.2002C6.08009 4.5 5.51962 4.5 5.0918 4.71799C4.71547 4.90973 4.40973 5.21547 4.21799 5.5918C4 6.01962 4 6.58009 4 7.7002V8.5M8 4.5H16M8 4.5V2.5M16 4.5H16.8002C17.9203 4.5 18.4796 4.5 18.9074 4.71799C19.2837 4.90973 19.5905 5.21547 19.7822 5.5918C20 6.0192 20 6.57899 20 7.69691V8.5M16 4.5V2.5M4 8.5V17.3002C4 18.4203 4 18.9801 4.21799 19.4079C4.40973 19.7842 4.71547 20.0905 5.0918 20.2822C5.5192 20.5 6.07899 20.5 7.19691 20.5H16.8031C17.921 20.5 18.48 20.5 18.9074 20.2822C19.2837 20.0905 19.5905 19.7842 19.7822 19.4079C20 18.9805 20 18.4215 20 17.3036V8.5M4 8.5H20M16 16.5H16.002L16.002 16.502L16 16.502V16.5ZM12 16.5H12.002L12.002 16.502L12 16.502V16.5ZM8 16.5H8.002L8.00195 16.502L8 16.502V16.5ZM16.002 12.5V12.502L16 12.502V12.5H16.002ZM12 12.5H12.002L12.002 12.502L12 12.502V12.5ZM8 12.5H8.002L8.00195 12.502L8 12.502V12.5Z"
                      stroke="#0F0F0F"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {startDate}
                </span>
                <button className="diary-view" onClick={handleNavigate}>
                  Ver evento
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M10.307 13.6923L15.1532 8.84619M20.1106 5.88867L16.0199 19.1833C15.6533 20.3747 15.4699 20.9707 15.1537 21.1683C14.8795 21.3396 14.5399 21.3683 14.2412 21.2443C13.8968 21.1014 13.6173 20.5433 13.0596 19.428L10.4687 14.2461C10.3802 14.0691 10.3359 13.981 10.2768 13.9043C10.2243 13.8363 10.1638 13.7749 10.0957 13.7225C10.0208 13.6647 9.93413 13.6214 9.76504 13.5369L4.57119 10.9399C3.45589 10.3823 2.89818 10.1032 2.75527 9.75879C2.63133 9.4601 2.6596 9.12023 2.83096 8.84597C3.02854 8.52974 3.6245 8.34603 4.81631 7.97932L18.1109 3.88867C19.0479 3.60038 19.5165 3.45635 19.833 3.57253C20.1087 3.67373 20.326 3.89084 20.4272 4.16651C20.5433 4.48283 20.3992 4.95126 20.1112 5.88729L20.1106 5.88867Z"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="back-diary-view-all">
        <NavLink
          to="/agenda"
          className="diary-view-all"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Ver más
          {hover && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M13.2681 3.66865C13.5682 3.3687 13.9751 3.2002 14.3993 3.2002C14.8236 3.2002 15.2305 3.3687 15.5305 3.66865L22.7305 10.8687C23.0305 11.1687 23.199 11.5756 23.199 11.9999C23.199 12.4241 23.0305 12.831 22.7305 13.1311L15.5305 20.3311C15.2288 20.6225 14.8246 20.7838 14.4051 20.7801C13.9856 20.7765 13.5843 20.6082 13.2876 20.3116C12.991 20.0149 12.8227 19.6136 12.819 19.1941C12.8154 18.7746 12.9767 18.3704 13.2681 18.0687L17.5993 13.5999H2.39932C1.97497 13.5999 1.568 13.4313 1.26795 13.1312C0.967887 12.8312 0.799316 12.4242 0.799316 11.9999C0.799316 11.5755 0.967887 11.1685 1.26795 10.8685C1.568 10.5684 1.97497 10.3999 2.39932 10.3999H17.5993L13.2681 5.93105C12.9682 5.63101 12.7997 5.22412 12.7997 4.79985C12.7997 4.37559 12.9682 3.9687 13.2681 3.66865Z"
                fill="white"
              />
            </svg>
          )}
        </NavLink>
      </div>
    </div>
  );
};

export default Diary;
