import React from 'react'
import  LogoPrincipal  from '../assets/logo-principal.png'
import '../styles/pages/Error.css';

export const Error = () => {
  return (
    <div className="error-div">
      <p className="error-text">Página no encontrada</p>
      <p className="error-text">Error 404</p>
      <img  className="error-img" src={LogoPrincipal}/>
    </div>
  )
}
