import React, { useState, useEffect } from 'react';
import Navbar from '../components/common/navbar/Navbar'
import {Footer} from '../components/home/Footer'
import '../styles/pages/Planes.css'
import Cascos1 from '../assets/planes/casco1.png'
import Cascos2 from '../assets/planes/casco2.png'
import Cascos3 from '../assets/planes/casco3.png'
import Cascos4 from '../assets/planes/casco4.png'
import Cascos5 from '../assets/planes/casco5.png'
import BounceLoader from "react-spinners/BounceLoader";

export const Planes = () => {

    const [toggleTextDetail1, setToggleTextDetail1] = useState(false);
    const [toggleTextDetail2, setToggleTextDetail2] = useState(false);
    const [toggleTextDetail3, setToggleTextDetail3] = useState(false);
    const [toggleTextDetail4, setToggleTextDetail4] = useState(false);
    const [toggleTextDetail5, setToggleTextDetail5] = useState(false);

    const [copied, setCopied] = useState(false);

    const [clicado1, setClicado1] = useState(false);
    const [clicado2, setClicado2] = useState(false);
    const [clicado3, setClicado3] = useState(false);
    const [clicado4, setClicado4] = useState(false);
    const [clicado5, setClicado5] = useState(false);

    //Copiar URL con el boton de compartir
    const copyToClipboard = () => {
      const urlToCopy = window.location.href;
      navigator.clipboard.writeText(urlToCopy).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // RESET COPIADO DENTRO DE DOS SEGUNDOS
      });
    };
    //Muestra los detalles cuando se dan click en los handleClockDetailN
    const handleClickDetail1 = () => {
        setToggleTextDetail1(!toggleTextDetail1);
        // Cerrar los otros detalles cuando se abre uno nuevo
        setToggleTextDetail2(false);
        setToggleTextDetail3(false);
        setToggleTextDetail4(false);
        setToggleTextDetail5(false);
        setClicado1(true);
        setClicado2(false);
        setClicado3(false);
        setClicado4(false);
        setClicado5(false);
      };
    
      const handleClickDetail2 = () => {
        setToggleTextDetail2(!toggleTextDetail2);
        // Cerrar los otros detalles cuando se abre uno nuevo
        setToggleTextDetail1(false);
        setToggleTextDetail3(false);
        setToggleTextDetail4(false);
       setToggleTextDetail5(false);
       setClicado2(true);
       setClicado1(false);
       setClicado3(false);
       setClicado4(false);
       setClicado5(false);
      };
    
      const handleClickDetail3 = () => {
        setToggleTextDetail3(!toggleTextDetail3);
        // Cerrar los otros detalles cuando se abre uno nuevo
        setToggleTextDetail1(false);
        setToggleTextDetail2(false);
        setToggleTextDetail4(false);
        setToggleTextDetail5(false);
        setClicado3(true);
        setClicado2(false);
        setClicado1(false);
        setClicado4(false);
        setClicado5(false);
      };
    
      const handleClickDetail4 = () => {
        setToggleTextDetail4(!toggleTextDetail4);
        // Cerrar los otros detalles cuando se abre uno nuevo
        setToggleTextDetail1(false);
        setToggleTextDetail2(false);
        setToggleTextDetail3(false);
        setToggleTextDetail5(false);
        setClicado4(true);
        setClicado2(false);
        setClicado3(false);
        setClicado1(false);
        setClicado5(false);
      };

      const handleClickDetail5 = () => {
        setToggleTextDetail5(!toggleTextDetail5);
        // Cerrar los otros detalles cuando se abre uno nuevo
        setToggleTextDetail1(false);
        setToggleTextDetail2(false);
        setToggleTextDetail3(false);
        setToggleTextDetail4(false);
        setClicado5(true);
        setClicado2(false);
        setClicado3(false);
        setClicado4(false);
        setClicado1(false);
      };

      const [loading, setLoading] = useState(true);

      useEffect(() => {
          // Simular carga de datos
          const timer = setTimeout(() => {
              setLoading(false);
          }, 2000); // Cambia este valor al tiempo que realmente tardan tus datos en cargar
  
          // Limpiar el timer si el componente se desmonta antes de que se complete la carga
          return () => clearTimeout(timer);
      }, []);

  return (
    <div>
        {loading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh" }}>
          <BounceLoader color="#0e2b60" />
        </div>
        ) : (
        <>
        <Navbar />
        <div className='planes-margin'>
        <div className='back-planes-img'>
            <div className='back-planes-img-row'>
            <h1 className='title-planes'>Planes de afiliación</h1>
            <p className='sub-planes'>Próximamente</p>
            </div>
            {/*SE MUESTRA EN SIN RESPONSIVE*/}
            <p className='desc-planes'>Explora los beneficios de<br/>  nuestras categorias</p>
            <p className='desc-planes2'>Maximiza tu potencial con los beneficios de las<br/> categorías de FISEL</p>
            {/*SE MUESTRA EN RESPONSIVE*/}
            <p className='desc-planes-responsive'>Explora los beneficios de nuestras categorias</p>
            <p className='desc-planes2-responsive'>Maximiza tu potencial con los beneficios de las categorías de FISEL</p>

            <div className='back-buttons-planes' >
              {/*BOTON DE WHATSSAPP*/}
                <a className='buttons-planes-wp' href="https://wa.link/11mq3i" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M19.0498 4.91006C18.133 3.98399 17.041 3.24973 15.8374 2.75011C14.6339 2.25049 13.3429 1.99552 12.0398 2.00006C6.5798 2.00006 2.1298 6.45006 2.1298 11.9101C2.1298 13.6601 2.5898 15.3601 3.4498 16.8601L2.0498 22.0001L7.2998 20.6201C8.7498 21.4101 10.3798 21.8301 12.0398 21.8301C17.4998 21.8301 21.9498 17.3801 21.9498 11.9201C21.9498 9.27006 20.9198 6.78006 19.0498 4.91006ZM12.0398 20.1501C10.5598 20.1501 9.1098 19.7501 7.8398 19.0001L7.5398 18.8201L4.4198 19.6401L5.2498 16.6001L5.0498 16.2901C4.22755 14.977 3.79094 13.4593 3.7898 11.9101C3.7898 7.37006 7.4898 3.67006 12.0298 3.67006C14.2298 3.67006 16.2998 4.53006 17.8498 6.09006C18.6173 6.85402 19.2255 7.76272 19.6392 8.76348C20.0529 9.76425 20.2638 10.8372 20.2598 11.9201C20.2798 16.4601 16.5798 20.1501 12.0398 20.1501ZM16.5598 13.9901C16.3098 13.8701 15.0898 13.2701 14.8698 13.1801C14.6398 13.1001 14.4798 13.0601 14.3098 13.3001C14.1398 13.5501 13.6698 14.1101 13.5298 14.2701C13.3898 14.4401 13.2398 14.4601 12.9898 14.3301C12.7398 14.2101 11.9398 13.9401 10.9998 13.1001C10.2598 12.4401 9.7698 11.6301 9.6198 11.3801C9.4798 11.1301 9.5998 11.0001 9.7298 10.8701C9.8398 10.7601 9.9798 10.5801 10.0998 10.4401C10.2198 10.3001 10.2698 10.1901 10.3498 10.0301C10.4298 9.86006 10.3898 9.72006 10.3298 9.60006C10.2698 9.48006 9.7698 8.26006 9.5698 7.76006C9.3698 7.28006 9.1598 7.34006 9.0098 7.33006H8.5298C8.3598 7.33006 8.0998 7.39006 7.8698 7.64006C7.6498 7.89006 7.0098 8.49006 7.0098 9.71006C7.0098 10.9301 7.89981 12.1101 8.0198 12.2701C8.1398 12.4401 9.7698 14.9401 12.2498 16.0101C12.8398 16.2701 13.2998 16.4201 13.6598 16.5301C14.2498 16.7201 14.7898 16.6901 15.2198 16.6301C15.6998 16.5601 16.6898 16.0301 16.8898 15.4501C17.0998 14.8701 17.0998 14.3801 17.0298 14.2701C16.9598 14.1601 16.8098 14.1101 16.5598 13.9901Z" fill="white"/>
                    </svg>
                </a>
                {/*BOTON DE COMPARTIR*/}
                <a className='buttons-planes-cmp' onClick={copyToClipboard}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <mask id="path-1-inside-1_3264_691" fill="white">
                    <path d="M19.59 12L15 7.41V9.87L14.14 10C9.83 10.61 6.91 12.87 5.24 16.33C7.56 14.69 10.44 13.9 14 13.9H15V16.59M13 14.92C8.53 15.13 5.33 16.74 3 20C4 15 7 10 14 9V5L21 12L14 19V14.9C13.67 14.9 13.34 14.91 13 14.92Z"/>
                    </mask>
                    <path d="M19.59 12L15 7.41V9.87L14.14 10C9.83 10.61 6.91 12.87 5.24 16.33C7.56 14.69 10.44 13.9 14 13.9H15V16.59M13 14.92C8.53 15.13 5.33 16.74 3 20C4 15 7 10 14 9V5L21 12L14 19V14.9C13.67 14.9 13.34 14.91 13 14.92Z" fill="white"/>
                    <path d="M12 14.9V14.92H14V14.9H12ZM15 7.41L16.4142 5.99579L13 2.58157V7.41H15ZM15 9.87L15.2989 11.8475L17 11.5904V9.87H15ZM14.14 10L14.4203 11.9803L14.4296 11.9789L14.4389 11.9775L14.14 10ZM5.24 16.33L3.43883 15.4606L0.0747397 22.4306L6.39447 17.9632L5.24 16.33ZM15 13.9H17V11.9H15V13.9ZM13 14.92L12.9412 12.9209L12.9237 12.9214L12.9061 12.9222L13 14.92ZM3 20L1.03884 19.6078L4.62713 21.1629L3 20ZM14 9L14.2828 10.9799L16 10.7346V9H14ZM14 5L15.4142 3.58579L12 0.171573V5H14ZM21 12L22.4142 13.4142L23.8284 12L22.4142 10.5858L21 12ZM14 19H12V23.8284L15.4142 20.4142L14 19ZM14 14.9H16V12.9H14V14.9ZM21.0042 10.5858L16.4142 5.99579L13.5858 8.82421L18.1758 13.4142L21.0042 10.5858ZM13 7.41V9.87H17V7.41H13ZM14.7011 7.89247L13.8411 8.02247L14.4389 11.9775L15.2989 11.8475L14.7011 7.89247ZM13.8597 8.01974C8.89646 8.72219 5.40196 11.3933 3.43883 15.4606L7.04117 17.1994C8.41804 14.3467 10.7635 12.4978 14.4203 11.9803L13.8597 8.01974ZM6.39447 17.9632C8.29564 16.6192 10.7415 15.9 14 15.9V11.9C10.1385 11.9 6.82436 12.7608 4.08553 14.6968L6.39447 17.9632ZM14 15.9H15V11.9H14V15.9ZM13 13.9V16.59H17V13.9H13ZM12.9061 12.9222C7.91418 13.1567 4.10842 15.0096 1.37287 18.8371L4.62713 21.1629C6.55157 18.4704 9.14582 17.1033 13.0939 16.9178L12.9061 12.9222ZM4.96116 20.3922C5.41429 18.1266 6.29864 15.9816 7.75515 14.317C9.18184 12.6865 11.2439 11.414 14.2828 10.9799L13.7172 7.0201C9.75614 7.58596 6.81816 9.31349 4.74485 11.683C2.70136 14.0184 1.58571 16.8734 1.03884 19.6078L4.96116 20.3922ZM16 9V5H12V9H16ZM12.5858 6.41421L19.5858 13.4142L22.4142 10.5858L15.4142 3.58579L12.5858 6.41421ZM19.5858 10.5858L12.5858 17.5858L15.4142 20.4142L22.4142 13.4142L19.5858 10.5858ZM16 19V14.9H12V19H16ZM14 12.9C13.635 12.9 13.2743 12.9111 12.9412 12.9209L13.0588 16.9191C13.4057 16.9089 13.705 16.9 14 16.9V12.9Z" fill="white" mask="url(#path-1-inside-1_3264_691)"/>
                    </svg>
                </a>
                {copied && <span style={{ marginLeft: '5px', color: 'green' }}>Copiado!</span>}
            </div>
            <p className='wpp-text-planes'>Escribenos directamente a nuestro WhatsApp</p>

            <div className='back-cascos-img'>
                <div className='back-cascos'>
                    {/*El casco que hacemos click, casco1, despliega lo que hay en toggleTextDetail1*/}
                    <div className='back-cascos-detail1'>
                        <div className={`cascos1 ${clicado1 ? 'active' : ''}`} onClick={handleClickDetail1}>
                            <img className="img-casco1" src={Cascos1}/>
                        </div>
                        <p className='text-cascos'>LIDER</p>
                    </div>
                    {/*El casco que hacemos click, casco2, despliega lo que hay en toggleTextDetail2*/}
                    <div className='back-cascos-detail2'>
                        <div className={`cascos1 ${clicado2 ? 'active' : ''}`} onClick={handleClickDetail2}>
                            <img className="img-casco1" src={Cascos2}/>
                        </div>
                        <p className='text-cascos'>ALPHA</p>                     
                    </div>
                    {/*El casco que hacemos click, casco3, despliega lo que hay en toggleTextDetail3*/}
                    <div className='back-cascos-detail3'>
                        <div className={`cascos1 ${clicado3 ? 'active' : ''}`} onClick={handleClickDetail3}>
                            <img className="img-casco1" src={Cascos3}/>
                        </div>
                        <p className='text-cascos'>DELTA</p>
                    </div>
                    {/*El casco que hacemos click, casco4, despliega lo que hay en toggleTextDetail4*/}
                    <div className='back-cascos-detail4'>
                        <div className={`cascos1 ${clicado4 ? 'active' : ''}`} onClick={handleClickDetail4}>
                            <img className="img-casco1" src={Cascos4}/>
                        </div>
                        <p className='text-cascos'>SIGMA</p>
                    </div>
                    {/*El casco que hacemos click, casco5, despliega lo que hay en toggleTextDetail5*/}
                    <div className='back-cascos-detail5'>
                        <div className={`cascos1 ${clicado5 ? 'active' : ''}`} onClick={handleClickDetail5}>
                            <img className="img-casco1" src={Cascos5}/>
                        </div>
                        <p className='text-cascos'>OMEGA</p>
                    </div>
                </div>
            </div>

            {/*Se despliega cuando se da click en responsive muestra una card, sin responsive un texto al lado izquierdo para casco1*/}
            {toggleTextDetail1 ? (
            <div className='toogle-text'>
              {/* Contenido que se mostrará cuando se haga clic */}
              <div className='back-planes-responsive'>
                <button
                  onClick={()=>setToggleTextDetail1(!toggleTextDetail1)}
                  className="TodoForm-button2 TodoForm-button--cancel2"
                  type="button"
                >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M18 6L6 18M6 6L18 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                </button>
              <img className="img-casco1-bi" src={Cascos1}/>
              <h1 className='text-detail-planes'>LIDER</h1>
              <ul className='text-detail-planes-ul'>
              Renovación directa sin escalar las diferentes categorías.
              <li>Folio de registro como mienbro de la FEDERACIÓN.</li>
              <li>Kit  de artículos oficiales FISEL físicos y digitales.</li>
              <li>Acceso a grupo general de integradores FISEL.</li>
              <li>Acceso a Cascos Seguros.</li>
              <li>Apoyo y asesoría comercial y de marketing.</li>
              <li>Herramientas de publicidad para Redes Sociales.</li>
              <li>Asesoría y respaldo contable.</li>
              <li>Descuento en Cursos y capacitaciones.</li>
              <li>Participación en proyectos locales.</li>
              <li>Acceso a Arkhana.</li>
              <li>Financiamiento para proyectos.</li>
              <li>Folio de registro como miembro de la FEDERACIÓN.</li>
              <li>Uso de Logo FISEL.</li>
              <li>Publicación de Logotipo página web de FISEL.</li>
              <li>Descuento en productos.</li>
              <li>Participación en proyectos Internacionales.</li>
              <li>Asesoria y respaldo Jurídico.</li>
              </ul>
              </div>
            </div>
            ) : null}
            {/*Se despliega cuando se da click en responsive muestra una card, sin responsive un texto al lado izquierdo casco2*/}
            {toggleTextDetail2 ? (
            <div className='toogle-text'>
              <div className='back-planes-responsive'>
              <button
                  onClick={()=>setToggleTextDetail2(!toggleTextDetail2)}
                  className="TodoForm-button2 TodoForm-button--cancel2"
                  type="button"
                >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M18 6L6 18M6 6L18 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                </button>
              <img className="img-casco2-bi" src={Cascos2}/>
              {/* Contenido que se mostrará cuando se haga clic */}
              <h1 className='text-detail-planes'>ALPHA</h1>
              <ul className='text-detail-planes-ul'>
              <li>Acceso a grupo general de Integradores FISEL.</li>
              <li>Acceso a Cascos Seguros. Apoyo y asesoría Comercial y de Marketing.</li>
              <li>Herramientas de publicidad para Redes Sociales.</li>
              <li>Asesoría y respaldo contable.</li>
              <li>Descuento en Cursos y capacitaciones.</li> 
              <li>Participación en proyectos locales.</li>
              <li>Acceso a Arkhana</li>
              <li>Financiamiento para proyectos Folio de Registro como miembro de la FEDERACION.</li>
              <li>Uso de Logo FISEL.</li> 
              <li>Publicación de Logotipo en página web de FISEL.</li>
              </ul>
              </div>
            </div>
            ) : null}
            {/*Se despliega cuando se da click en responsive muestra una card, sin responsive un texto al lado izquierdo casco3*/}
            {toggleTextDetail3 ? (
            <div className='toogle-text'>
              {/* Contenido que se mostrará cuando se haga clic */}
              <div className='back-planes-responsive'>
              <button
                  onClick={()=>setToggleTextDetail3(!toggleTextDetail3)}
                  className="TodoForm-button2 TodoForm-button--cancel2"
                  type="button"
                >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M18 6L6 18M6 6L18 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                </button>
              <img className="img-casco3-bi" src={Cascos3}/>
              <h1 className='text-detail-planes'>DELTA</h1>
              <ul className='text-detail-planes-ul'>
              <li>Acceso a grupo general de Integradores FISEL.</li>
              <li> Acceso a Cascos Seguros. Apoyo y asesoría Comercial y de Marketing.</li>
              <li>Herramientas de publicidad para Redes Sociales.</li>
              <li>Asesoría y respaldo contable. Descuento en Cursos y capacitaciones.</li>
              <li>Participación en proyectos locales. Acceso a Arkhana</li>
              </ul>
              </div>
            </div>
            ) : null}
            {/*Se despliega cuando se da click en responsive muestra una card, sin responsive un texto al lado izquierdo casco4*/}
            {toggleTextDetail4 ? (
            <div className='toogle-text'>
              <div className='back-planes-responsive'>
              {/* Contenido que se mostrará cuando se haga clic */}
              <button
                  onClick={()=>setToggleTextDetail4(!toggleTextDetail4)}
                  className="TodoForm-button2 TodoForm-button--cancel2"
                  type="button"
                >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M18 6L6 18M6 6L18 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                </button>
              <img className="img-casco4-bi" src={Cascos4}/>
              <h1 className='text-detail-planes'>SIGMA</h1>
              <ul className='text-detail-planes-ul'>
              <li>Acceso a grupo general de Integradores FISEL. Acceso a Cascos Seguros.</li> 
              <li>Apoyo y asesoría comercial y de Marketing.</li>
              <li>Herramientas de publicidad para Redes Sociales.</li>
              </ul>
              </div>
            </div>
            ) : null}
            {/*Se despliega cuando se da click en responsive muestra una card, sin responsive un texto al lado izquierdo casco5*/}
            {toggleTextDetail5 ? (
            <div className='toogle-text'>
              <div className='back-planes-responsive'>
              {/* Contenido que se mostrará cuando se haga clic */}
              <button
                  onClick={()=>setToggleTextDetail5(!toggleTextDetail5)}
                  className="TodoForm-button2 TodoForm-button--cancel2"
                  type="button"
                >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M18 6L6 18M6 6L18 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                </button>
              <img className="img-casco4-bi" src={Cascos4}/>
              <h1 className='text-detail-planes'>OMEGA</h1>
              <ul className='text-detail-planes-ul'>
              <li>Acceso a grupo general de Integradores FISEL</li>
              </ul>
              </div>
            </div>
            ) : null}
        </div>
    </div>
    <Footer />
    </>
    )}
    </div>
  )
}
