import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Avatar, Box, Button, IconButton, Typography } from "@mui/material";
import LogoAdmin from "../../assets/Logo-admin.png";

// ---- icons ------------------------------------------
import {
  UserCircleIcon,
  PhotoIcon,
  NewspaperIcon,
  CalendarIcon,
  ArrowLeftCircleIcon,
  UsersIcon,
  CheckBadgeIcon,
  ChevronLeftIcon,
} from "@heroicons/react/24/outline";

import styled from "styled-components";
import SidebarMenuParent from "../common/SidebarMenu/SidebarMenuParent";
import SidebarMenu from "../common/SidebarMenu/SidebarMenu";
import { onCloseSidebar, toggleSidebar } from "../../redux/slice/sidebarSlice";
import { useDispatch } from "react-redux";

const SideBar = ({ user, onClose }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [path, setPath] = useState("/admin/profile");

  const pathname = location.pathname;
  useEffect(() => {
    setPath(pathname);
  }, [pathname]);

  const navTabs = [
    {
      label: "Perfil",
      id: "/admin/profile",
      icon: <UserCircleIcon />,
      path: "/admin/profile",
    },
    {
      label: "Banners",
      id: "/admin/banners",
      icon: <PhotoIcon />,
      path: "/admin/banners",
    },
    {
      label: "Usuarios",
      id: "/admin/users",
      icon: <UsersIcon />,
      path: "/admin/users",
    },
  ];

  const navTabsChildren = [
    {
      label: "Noticias",
      icon: <NewspaperIcon style={{ width: "22px" }} />,
      path: "/admin/news",
      childrens: [
        {
          label: "Listado",
          path: "/admin/news",
        },
        {
          label: "Publicar",
          path: "/admin/news/create",
        },
        {
          label: "Editar",
          path: "/admin/news/:id/edit",
          disabled: true,
        },
      ],
    },
    {
      label: "Agenda",
      icon: <CalendarIcon style={{ width: "22px" }} />,
      path: "/admin/diary",
      childrens: [
        {
          label: "Listado",
          path: "/admin/diary",
        },
        {
          label: "Crear",
          path: "/admin/diary/create",
        },
      ],
    },
    {
      label: "Partners",
      icon: <CheckBadgeIcon />,
      path: "/admin/partners",
      childrens: [
        {
          label: "Listado",
          path: "/admin/partners",
        },
        {
          label: "Agregar",
          path: "/admin/partners/create",
        },
        {
          label: "Editar",
          path: "/admin/partners/:id/edit",
          disabled: true,
        },
      ],
    },
  ];

  const handleClick = (path) => {
    if (path) {
      navigate(path);
    }
  };

  const dispatch = useDispatch();

  const handleCloseSidebar = () => {
    dispatch(toggleSidebar());
  };

  return (
    <Component>
      <Box className="container" style={{ overflow: "auto" }}>
        <Box>
          <Box className="header-component">
            <div className="logo-container">
              <img src={LogoAdmin} alt="Logo admin" />
            </div>
            <Box>
              <IconButton onClick={handleCloseSidebar}>
                <ArrowLeftCircleIcon className="box-icons close" />
              </IconButton>
            </Box>
          </Box>

          <Box>
            <Box className="navigation-container" style={{ overflow: "auto" }}>
              <SidebarMenu
                sections={navTabs}
                onClick={handleClick}
                path={path}
              />
              <SidebarMenuParent
                sections={navTabsChildren}
                onClick={handleClick}
                path={path}
              />
              <Button
                className="navigation landing"
                onClick={() => navigate("/")}
              >
                <Box className="box-icons">
                  <ChevronLeftIcon />
                </Box>
                <p>Landing</p>
              </Button>
            </Box>
          </Box>
        </Box>

        <Box className="footer-component">
          <Link
            to="/admin/profile"
            className="link-user"
            onClick={() => dispatch(onCloseSidebar())}
          >
            <Avatar src={user?.photo} className="avatar" />
            <Box className="name-email-container">
              <Typography className="user-name">{`${user?.firstname} ${user?.lastname}`}</Typography>
              <Typography className="user-email">{user?.email}</Typography>
            </Box>
          </Link>
        </Box>
      </Box>
    </Component>
  );
};

const Component = styled.div`
  border-right: 1px solid #e2e8f0;
  height: 100vh;
  position: sticky;
  top: 0;
  background-color: white;

  .container {
    padding: 38px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    height: 100%;
  }

  .header-component {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .link-landing {
    display: flex;
    align-items: center;
    // justify-content: center;
    gap: 10px;
    text-decoration: none;
    margin-top: 50px;
    border: 1px solid #9bd1e1;
    padding: 5px;
    border-radius: 10px;
    color: #0072a7;
  }

  .arrow-icon {
    width: 25px;
  }

  .navigation-container {
    margin-bottom: 20px;
  }

  .label-sections {
    font-weight: 900;
    color: #919eab;
    font-size: 14px;
    margin-bottom: 15px;
  }

  .footer-component {
  }

  .link-user {
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid #e2e8f0;
    border-radius: 10px;
    padding: 10px;
  }

  .avatar {
  }

  .name-email-container {
    display: flex;
    flex-direction: column;
  }
  .user-name {
    font-size: 14px;
    color: black;
  }
  .user-email {
    font-size: 12px;
    color: gray;
  }

  .navigation {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
    border: none;
    padding: 10px;
    width: 250px;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;
    color: gray;
    font-size: 12px;
  }

  .navigation.active {
    background-color: #ebf5f8;
    color: #0072a7;
    font-weight: 700;
  }

  .navigation:hover {
    background-color: #f6f7f9;
  }

  .navigation.landing {
    margin-top: 5px;
  }

  .box-icons {
    height: 20px;
    width: 20px;
  }

  .box-icons.close {
    display: none;
    height: 30px;
    width: 30px;
    color: #ccc;
  }

  @media (max-width: 1000px) {
    .box-icons.close {
      display: block;
    }
  }
`;

export default SideBar;
