import React, { useState } from "react";
import logout from "../../../utils/logout";
import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import styled from "styled-components";
import BodyCard from "../../common/bodyCard/BodyCard";
import HeaderCard from "../../common/headerCard/HeaderCard";
import { Helmet } from "react-helmet";
import UserData from "./Options/UserData";
import PropTypes from "prop-types";
import UserCredentials from "./Options/UserCredentials";
import DangerZone from "./Options/DangerZone";
import { Toaster } from "react-hot-toast";
import {
  ArrowLeftStartOnRectangleIcon,
  ExclamationTriangleIcon,
  IdentificationIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

const PerfilAdmin = ({ user, isLoading }) => {
  const [value, setValue] = useState(0);

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <StyledContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Fisel | Dasboard - ${user?.firstname} ${user?.lastname}`}</title>
      </Helmet>
      <HeaderCard
        title={`Hola ${user?.firstname}! 🖐️`}
        leftContent={
          <Button
            variant="outlined"
            onClick={logout}
            color="error"
            startIcon={
              <ArrowLeftStartOnRectangleIcon style={{ width: "18px" }} />
            }
          >
            Logout
          </Button>
        }
      />
      <BodyCard>
        <Container>
          <Box className="component">
            <TabPanel value={value} index={0} className="tabPanel">
              <UserData user={user} isLoading={isLoading} />
            </TabPanel>
            <TabPanel value={value} index={1} className="tabPanel">
              <UserCredentials user={user} isLoading={isLoading} />
            </TabPanel>
            <TabPanel value={value} index={2} className="tabPanel">
              <DangerZone user={user} isLoading={isLoading} />
            </TabPanel>
            <Tabs
              value={value}
              onChange={handleChangeTabs}
              orientation="vertical"
              sx={{
                borderLeft: 1,
                textAlign: "right",
                borderColor: "divider",
                "& .MuiTabs-indicator": {
                  left: 0,
                },
                "& .MuiTabs-flexContainer": {
                  justifyContent: "flex-start",
                  textAlign: "start",
                },
              }}
            >
              <Tab
                label="Datos"
                icon={<UserIcon className="tab-icon" />}
                iconPosition="start"
                sx={{
                  justifyContent: "flex-start",
                  textAlign: "left",
                  fontSize: "12px",
                  padding: "15px 10px",
                  minHeight: "auto",
                }}
              />
              <Tab
                label="Credenciales"
                icon={<IdentificationIcon className="tab-icon" />}
                iconPosition="start"
                sx={{
                  justifyContent: "flex-start",
                  textAlign: "left",
                  fontSize: "12px",
                  padding: "15px 10px",
                  minHeight: "auto",
                }}
              />
              <Tab
                label="Danger zone"
                icon={<ExclamationTriangleIcon className="tab-icon" />}
                iconPosition="start"
                sx={{
                  justifyContent: "flex-start",
                  textAlign: "left",
                  fontSize: "12px",
                  padding: "15px 10px",
                  minHeight: "auto",
                }}
              />
            </Tabs>
          </Box>
        </Container>
      </BodyCard>
      <Toaster position="top-right" />
    </StyledContainer>
  );
};

export default PerfilAdmin;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Container = styled.div`
  .component {
    display: flex;
    gap: 30px;
    align-items: start;
    justify-content: space-between;
  }

  .tabPanel {
    max-width: 400px;
    width: 100%;
    padding: 0;
  }

  .tab-icon {
    width: 18px;
  }

  @media (max-width: 700px) {
    .component {
      flex-direction: column;
    }
  }
`;

const StyledContainer = styled.div``;
