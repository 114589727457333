import { TextField } from "@mui/material";
import React from "react";
import styled from "styled-components";

const CustomTextField = ({
  name,
  defaultValue,
  value,
  onChange,
  rows,
  multiline,
  label,
  fullWidth,
  style,
  placeholder,
  disabled,
}) => {
  return (
    <StyledTextField
      name={name}
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      label={label}
      multiline={multiline}
      rows={rows}
      style={style}
      placeholder={placeholder}
      disabled={disabled}
      fullWidth={fullWidth}
    />
  );
};

const StyledTextField = styled(TextField)`
  & .MuiInputLabel-root {
    color: #9aa8c8;
  }

  & .MuiOutlinedInput-root {
    background-color: #f8fafc;

    &.Mui-focused {
      background-color: white;
    }

    & fieldset {
      border-color: #e5e7eb;
    }
  }
`;

export default CustomTextField;
