import instance from "../../config/axiosConfig";
import { setAllLogos, setLoadingLogos } from "../slice/logoSlice";

export const getLogos = () => {
  return async (dispatch) => {
    try {
      dispatch(setLoadingLogos(true));
      const response = await instance.get("/logo");
      const logos = response.data;
      dispatch(setAllLogos(logos));
    } catch (error) {
      console.error({ error });
    } finally {
      dispatch(setLoadingLogos(false));
    }
  };
};
