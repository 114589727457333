import React, { useEffect } from "react";
import "../../styles/others/Banner.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import { getBanners } from "../../redux/thunks/bannersFetchThunk";
import { Box, Skeleton } from "@mui/material";

const Banner = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      dispatch(getBanners());
    };
    fetchData();
  }, []);

  const banners = useSelector((state) => state.banners.allBanners);
  const isLoading = useSelector((state) => state.banners.isLoading);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    appendDots: (dots) => (
      <div className="custom-dots">
        <ul> {dots} </ul>
      </div>
    ),
  };

  const activeBanners = banners?.filter((e) => e?.isActive === true);

  if (isLoading)
    return (
      <Box style={{ marginBottom: "112px" }}>
        <Skeleton
          className="banner-component-skeleton"
          style={{ transform: "none", height: "396.3px" }}
        />
      </Box>
    );

  return (
    <div className="banner-component">
      <div className="slider-container">
        <Slider {...settings}>
          {activeBanners?.map((item, index) => {
            return (
              <img
                key={index}
                src={item?.url}
                alt="banner"
                className="banner-img"
              />
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default Banner;
