import React, { useState } from "react";
import { Box, Button, Collapse } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import styled from "styled-components";

const SidebarMenuParent = ({ sections, onClick, path }) => {
  const [openSection, setOpenSection] = useState(null);

  const handleParentClick = (sectionPath) => {
    setOpenSection((prevSection) =>
      prevSection === sectionPath ? null : sectionPath
    );
    onClick(sectionPath);
  };

  const handleChildClick = (childPath) => {
    onClick(childPath);
  };

  const isParentActive = (sectionPath) => {
    return path.startsWith(sectionPath);
  };

  // const isChildActive = (childPath) => {
  //   return path === childPath;
  // };

  const isChildActive = (childPath) => {
    // Function to check dynamic routes
    const matchDynamicRoute = (route, currentPath) => {
      const routeParts = route.split("/");
      const pathParts = currentPath.split("/");
      if (routeParts.length !== pathParts.length) return false;
      return routeParts.every(
        (part, index) => part.startsWith(":") || part === pathParts[index]
      );
    };

    return path === childPath || matchDynamicRoute(childPath, path);
  };

  return (
    <Box>
      {sections.map((section) => (
        <NavTabsComponent className="children" key={section.path}>
          <Button
            onClick={() => handleParentClick(section.path)}
            className={
              isParentActive(section.path)
                ? "navigation active father"
                : "navigation father"
            }
          >
            <Box className="icon-label-container">
              <Box className="box-icons">{section.icon}</Box>
              <p>{section.label}</p>
            </Box>
            <KeyboardArrowRightIcon className="arrow-icon" />
          </Button>

          <Collapse
            in={isParentActive(section.path)}
            timeout="auto"
            unmountOnExit
          >
            <Box className="childrens">
              {section.childrens.map((child) => (
                <Box className="children-container" key={child.path}>
                  <Button
                    onClick={() => handleChildClick(child.path)}
                    className={
                      isChildActive(child.path) ? "child active" : "child"
                    }
                    disabled={child.disabled}
                  >
                    <p>{child.label}</p>
                  </Button>
                </Box>
              ))}
            </Box>
          </Collapse>
        </NavTabsComponent>
      ))}
    </Box>
  );
};

const NavTabsComponent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 5px;

  .navigation.active.father {
    justify-content: space-between;
  }
  .navigation.father {
    justify-content: space-between;
  }

  .icon-label-container {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
  }

  .childrens {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: end;
  }
  .children-container {
    width: 200px;
  }

  .child {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
    border: none;
    padding: 7px 15px;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;
    color: gray;
    width: 100%;
    font-size: 12px;
  }

  .child:hover {
    background-color: #f6f7f9;
  }

  .child.active {
    background-color: #919eab14;
    color: #505050;
    font-weight: 600;
  }

  .arrow-icon {
    height: 18px;
    width: 18px;
  }
`;

export default SidebarMenuParent;
