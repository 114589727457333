import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";

const SchedulColumn = ({ schedul }) => {
  return (
    <Component>
      <Avatar src={schedul?.cover} variant="rounded" />
      <Box>
        <Typography className="title">{schedul?.title}</Typography>
        {/* <Typography>{schedul.}</Typography> */}
      </Box>
    </Component>
  );
};

const Component = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  text-align: start;
  height: 100%;

  .title {
    font-size: 13px;
  }
`;

export default SchedulColumn;
