import { configureStore } from "@reduxjs/toolkit";
import diarySlice from "../slice/diarySlice";
import newsSlice from "../slice/newsSlice";
import bannerSlice from "../slice/bannerSlice";
import loginSlice from "../slice/auth/loginSlice";
import loggedSlice from "../slice/auth/loggedSlice";
import logoSlice from "../slice/logoSlice";
import userSlice from "../slice/userSlice";
import sidebarSlice from "../slice/sidebarSlice";

export const store = configureStore({
  reducer: {
    login: loginSlice,
    logged: loggedSlice,
    news: newsSlice,
    diary: diarySlice,
    banners: bannerSlice,
    logos: logoSlice,
    users: userSlice,
    sidebar: sidebarSlice,
  },
});
