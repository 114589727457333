import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./pages/Home";
import { News } from "./pages/News";
import { Scheduls } from "./pages/Scheduls";
import { Error } from "./pages/Error";
import Login from "./pages/Login";
import { NewId } from "./pages/NewId";
import { Planes } from "./pages/Planes";
import CreateNewsAdmin from "./components/admin/News/CreateNewsAdmin";
import ProfileAdmin from "./components/admin/Profile/ProfileAdmin";
import CreateBannerAdmin from "./components/admin/Banner/CreateBannerAdmin";
import NewsAdmin from "./components/admin/News/NewsAdmin";
import DiaryAdmin from "./components/admin/Diary/DiaryAdmin";
import CreateDiaryAdmin from "./components/admin/Diary/CreateDiaryAdmin";
import AdminLayout from "./components/admin/AdminLayaout";
import ScheduleId from "./components/scheduls/ScheduleId";
import TableWithDraggableRows from "./components/admin/Table";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getUserLogged } from "./redux/thunks/loginFetchThunk";
import UsersAdmin from "./components/admin/Users/UsersAdmin";
import EditNewsAdmin from "./components/admin/News/EditNewsAdmin";
import PartnerCreate from "./components/admin/Partners/PartnerCreate";
import PartnersAdmin from "./components/admin/Partners/PartnersAdmin";

function App() {
  const dispatch = useDispatch();
  const { user, loading } = useSelector((state) => state.logged);

  useEffect(() => {
    dispatch(getUserLogged());
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/noticias" element={<News />} />
        <Route path="/noticias/:id" element={<NewId />} />
        <Route path="/agenda" element={<Scheduls />} />
        <Route path="/agenda/:id" element={<ScheduleId />} />

        {/* ----------------------------------------------------------------------------- */}
        <Route path="/admin" element={<AdminLayout user={user} />}>
          <Route
            index
            path="profile"
            element={<ProfileAdmin user={user} isLoading={loading} />}
          />
          <Route
            path="banners/historys"
            element={<TableWithDraggableRows user={user} />}
          />

          <Route path="banners" element={<CreateBannerAdmin user={user} />} />
          <Route path="news" element={<NewsAdmin user={user} />} />
          <Route path="news/create" element={<CreateNewsAdmin user={user} />} />
          <Route path="news/:id/edit" element={<EditNewsAdmin user={user} />} />
          <Route path="diary" element={<DiaryAdmin user={user} />} />
          <Route
            path="diary/create"
            element={<CreateDiaryAdmin user={user} />}
          />
          <Route path="partners" element={<PartnersAdmin user={user} />} />
          <Route
            path="partners/create"
            element={<PartnerCreate user={user} />}
          />
          <Route path="users" element={<UsersAdmin userlogged={user} />} />
        </Route>
        {/* ----------------------------------------------------------------------------- */}

        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Error />} />
        <Route path="/planes" element={<Planes />} />
      </Routes>
    </Router>
  );
}

export default App;
