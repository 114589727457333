import { createSlice } from "@reduxjs/toolkit";

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: {
    isVisible: false,
  },
  reducers: {
    toggleSidebar: (state) => {
      state.isVisible = !state.isVisible;
    },
    onCloseSidebar: (state) => {
      state.isVisible = false;
    },
  },
});

export const { toggleSidebar, onCloseSidebar } = sidebarSlice.actions;
export default sidebarSlice.reducer;
