import React from "react";
import Navbar from "../components/common/navbar/Navbar.jsx";
import Header from "../components/home/Header.jsx";
import Description from "../components/home/Description.jsx";
import "../styles/pages/Home.css";
import Partners from "../components/home/Partners.jsx";
import Allies from "../components/home/Allies.jsx";
import Testimonials from "../components/home/Testimonials.jsx";
import Diary from "../components/home/Diary.jsx";
import Banner from "../components/home/Banner.jsx";
import { Footer } from "../components/home/Footer.jsx";
import { Contact } from "../components/home/Contact.jsx";
import HelmetDetail from "../utils/HelmetDetail.jsx";

export const Home = () => {
  return (
    <div className="back-home">
      <HelmetDetail
        tabTitle="Fisel"
        title="Fisel"
        description="Comunidad Internacional de Profesionales en Seguridad Electrónica"
        type="website"
      />
      <Navbar />
      <Header />
      <Banner />
      <Description />
      <Partners />
      <Allies />
      <Testimonials />
      <Diary />
      <Contact />
      <Footer />
    </div>
  );
};
