import React from "react";

import {
  Box,
  Button,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import Tags from "../../../common/tags/Tags";
import CustomQuill from "../../../common/customQuill/CustomQuill";
import { useFormik } from "formik";
import styled from "styled-components";
import BodyCard from "../../../common/bodyCard/BodyCard";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Upload from "../../../common/upload/Upload";
import CustomTextField from "../../../common/textField/CustomTextField";

const NewsForm = ({ initialValues, onSubmit, label, loading }) => {
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <BodyCard
        header
        title="Detalles"
        subTitle="Titulo, Subtitulo, imagen..."
        leftContent={
          <Button
            variant="outlined"
            startIcon={<CloudUploadIcon />}
            type="submit"
            disabled={!formik.dirty || formik.isSubmitting}
          >
            {formik.isSubmitting && (
              <CircularProgress size={16} color="inherit" />
            )}
            {!formik.isSubmitting && label}
          </Button>
        }
      >
        {loading && (
          <CircularProgressContainer>
            <CircularProgress />
          </CircularProgressContainer>
        )}
        {!loading && (
          <Component>
            <Box className="button-gruop">
              <Typography className="label">Escrita por</Typography>
              <ToggleButtonGroup
                color="primary"
                value={formik.values.isByFisel}
                exclusive
                onChange={(_e, value) =>
                  formik.setFieldValue("isByFisel", value)
                }
                aria-label="Platform"
              >
                <ToggleButton value={true}>Fisel</ToggleButton>
                <ToggleButton value={false}>Partner</ToggleButton>
              </ToggleButtonGroup>
            </Box>

            <Upload
              title="Portada"
              onChange={(e) => {
                formik.setFieldValue("cover", e);
              }}
              value={formik.values.cover}
            />
            <CustomTextField
              name="title"
              placeholder="Titulo"
              value={formik.values.title}
              onChange={formik.handleChange}
              size="medium"
            />
            <CustomTextField
              name="subtitle"
              value={formik.values.subtitle}
              multiline
              row={4}
              placeholder="Subtitulo"
              onChange={formik.handleChange}
            />

            <Tags
              value={formik.values.tags || []}
              onChange={(e) => {
                formik.setFieldValue("tags", e);
              }}
            />

            <CustomQuill
              value={formik.values.body}
              onChange={(value) => formik.setFieldValue("body", value)}
            />
          </Component>
        )}
      </BodyCard>
    </form>
  );
};

const CircularProgressContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

const Component = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .switch {
    margin: 20px 0;
  }

  .label {
    font-size: 14px;
    color: #0009;
    margin-bottom: 8px;
  }

  .button-gruop {
    margin-top: 20px;
  }

  .avatar-container {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
  }

  .box-container {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .even-row {
    background-color: #f9f9f9;
  }

  .odd-row {
    background-color: #ffffff;
  }
`;

export default NewsForm;
