import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allLogos: null,
  isLoading: false,
};

const logoSlice = createSlice({
  name: "logo",
  initialState,
  reducers: {
    setAllLogos: (state, action) => {
      state.allLogos = action.payload;
    },
    setLoadingLogos: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setAllLogos, setLoadingLogos } = logoSlice.actions;
export default logoSlice.reducer;
