import React from "react";
import { Button, CircularProgress, TextField } from "@mui/material";
import { useFormik } from "formik";
import BodyCard from "../../../common/bodyCard/BodyCard";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Upload from "../../../common/upload/Upload";
import CustomTextField from "../../../common/textField/CustomTextField";

const BannerForm = ({ initialValues, onSubmit, label, key }) => {
  const formik = useFormik({
    initialValues,
    onSubmit,
    key: key,
    enableReinitialize: true,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <BodyCard
        header
        title="Subir banner"
        subTitle="1550x450px"
        leftContent={
          <Button
            variant="outlined"
            startIcon={<CloudUploadIcon />}
            type="submit"
            disabled={!formik.dirty || formik.isSubmitting}
          >
            {formik.isSubmitting && (
              <CircularProgress size={16} color="inherit" />
            )}
            {!formik.isSubmitting && label}
          </Button>
        }
      >
        <Upload
          style={{ marginTop: "20px" }}
          title="jpg, png, svg, avif, etc."
          onChange={(e) => {
            formik.setFieldValue("image", e);
          }}
          value={formik.values.image}
        />

        <CustomTextField
          placeholder="Referencia (opcional) - ejemplo: Banner valido hasta el dia..."
          fullWidth
          value={formik.values.reference}
          style={{ marginTop: "20px" }}
          onChange={(e) => formik.setFieldValue("reference", e.target.value)}
        />
      </BodyCard>
    </form>
  );
};

export default BannerForm;
