import React, { useState } from "react";
import Logo3 from "../assets/logo3.png";
import FlechaLogin from "../assets/flecha-login.png";
import { login } from "../redux/thunks/loginFetchThunk";
import OjoVisible from "../assets/ojo.png";
import OjoTachado from "../assets/ojo-tachado.png";
import "../styles/pages/Login.css";

import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const Login = () => {
  const isLoading = useSelector((state) => state.login.loading);

  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      dispatch(login(credentials));
    } catch (error) {
      console.error("Error al realizar la solicitud:", error.message);
    }
  };

  return (
    <div className="login-users">
      <div className="login-title">
        <p className="login-title-center">Inicio sesión</p>
      </div>
      <Link to="/" className="login-image-row">
        <img src={FlechaLogin} alt="FlechaLogin" />
      </Link>

      <div className="back-login-card">
        <div className="login-card">
          <div className="back-login-logo2">
            <img src={Logo3} className="login-logo2" alt="Logo3" />
          </div>
          <div>
            <form
              onSubmit={handleLogin}
              className="back-form"
              style={{ marginTop: "40px" }}
            >
              {/* ------------------ email --------------- */}
              <input
                type="text"
                name="email"
                required
                className="form-input"
                placeholder="E-mail"
                value={credentials.username}
                onChange={handleInputChange}
              />

              {/* ------------------ contraseña ---------- */}
              <div className="form-input-container">
                <input
                  name="password"
                  //required
                  className="form-input"
                  placeholder="Contraseña"
                  type={showPassword ? "text" : "password"}
                  value={credentials.password}
                  onChange={handleInputChange}
                />

                {/* ------------------ boton ------------ */}
                <span
                  className="password-toggle"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (
                    <img src={OjoVisible} alt="Ojo Visible" />
                  ) : (
                    <img src={OjoTachado} alt="Ojo Tachado" />
                  )}
                </span>
              </div>
              <br />
              <input
                style={{ marginTop: "150px" }}
                type="submit"
                value={isLoading ? "Cargando..." : "Iniciar sesión"}
                className="session-init"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
