import React, { useState } from "react";
import instance from "../../../config/axiosConfig";
import { Box } from "@mui/material";
import HeaderCard from "../../common/headerCard/HeaderCard";
import BodyCard from "../../common/bodyCard/BodyCard";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { getBanners } from "../../../redux/thunks/bannersFetchThunk";
import BannerForm from "./form/BannerForm";
import BannerAdminTable from "./table/BannerAdminTable";

const CreateBannerAdmin = () => {
  const initialValues = {
    image: null,
    reference: null,
    isActive: true,
  };

  const dispatch = useDispatch();

  const fetchData = async () => {
    dispatch(getBanners());
  };

  const [formKey, setFormKey] = useState(Date.now());

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await instance.post("banner", values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      resetForm();
      setFormKey(Date.now());
      fetchData();
      toast.success("Se subio el banner correctamente!");
    } catch (error) {
      toast.error("Error al subir el banner!");
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Component>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Fisel | Dashboard - Banner`}</title>
      </Helmet>

      <HeaderCard title="Banners" />
      <Box className="body">
        <BannerForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          label="Subir"
          key={formKey}
        />

        <BodyCard style={{ padding: "0" }}>
          <BannerAdminTable />
        </BodyCard>
      </Box>
      <Toaster position="top-right" />
    </Component>
  );
};

const Component = styled.div`
  .body {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .switch {
    margin: 20px 0;
  }

  .avatar-container {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
  }

  .box-container {
    height: 100%;
  }

  .even-row {
    background-color: #f9f9f9;
  }

  .odd-row {
    background-color: #ffffff;
  }
`;

export default CreateBannerAdmin;
