import React from "react";
import styled from "styled-components";
import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";

const ActiveColumn = ({ value }) => {
  return (
    <Component>
      {value ? (
        <CheckCircleIcon className="icons" />
      ) : (
        <XMarkIcon className="icons desactive" />
      )}
    </Component>
  );
};

const Component = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;

  .icons {
    width: 25px;
    color: green;
  }
  .icons.desactive {
    color: red;
  }
`;

export default ActiveColumn;
