import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  loading: false,
};

const loggedSlice = createSlice({
  name: "logged",
  initialState,
  reducers: {
    isLoading: (state, action) => {
      state.loading = action.payload;
    },
    loggedSucces: (state, action) => {
      state.loading = false;
      state.user = action.payload;
    },
  },
});

export const { isLoading, loggedSucces } = loggedSlice.actions;
export default loggedSlice.reducer;
