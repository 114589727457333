import React from "react";
import "../../styles/others/Description.css";
import Camara from "../../assets/camara.png";
// import Flecha from "../../assets/flecha.png";
import Cube from "../../assets/cube.png";
import { descriptions } from "./homeData/data.js";

import heroDescription1 from "../../assets/description/heroDescription1.png";
import heroDescription2 from "../../assets/description/heroDescription2.png";
import heroDescription3 from "../../assets/description/heroDescription3.png";
import heroDescription4 from "../../assets/description/heroDescription4.png";
import heroDescription5 from "../../assets/description/heroDescription5.png";

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const DescriptionCards = ({ indexImage, description }) => {
  const imageDictionry = {
    0: heroDescription1,
    1: heroDescription2,
    2: heroDescription3,
    3: heroDescription4,
    4: heroDescription5,
  };


  return (
    <div className="card-description-component">
      <img className="hero-description" src={imageDictionry[indexImage]} />
      <div className="div-description">
        <img className="cube-image" src={Cube} />
        <p className="description">{description}</p>
      </div>
    </div>
  );
};

const DescriptionCards1 = ({ indexImage, description }) => {
  const imageDictionry = {
    0: heroDescription1,
    1: heroDescription3,
  };


  return (
    <div className="card-description-component">
      <img className="hero-description" src={imageDictionry[indexImage]} />
      <div className="div-description">
        <img className="cube-image" src={Cube} />
        <p className="description">{description}</p>
      </div>
    </div>
  );
};


const DescriptionCards2 = ({ indexImage, description }) => {
  const imageDictionry = {
    0: heroDescription4,
    1: heroDescription5,
  };


  return (
    <div className="card-description-component">
      <img className="hero-description" src={imageDictionry[indexImage]} />
      <div className="div-description">
        <img className="cube-image" src={Cube} />
        <p className="description">{description}</p>
      </div>
    </div>
  );
};

const Description = () => {
  const videoUrl =
    "https://res.cloudinary.com/dwnsifial/video/upload/v1706036701/fisel.mp4";

    const filteredDescriptions1 = [descriptions[0], descriptions[1]];

    const filteredDescriptions2 = [descriptions[2]];

    const filteredDescriptions3 = [descriptions[3],descriptions[4]];


  // carrousel 500px
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
// Ajusta el espacio a los lados de los ítems centrales
    
  };

  return (
    <div className="back-description-home">
      <div className="card1-description">
        <h3 className="h3-description">¿Quienes Somos?</h3>
        <div className="card-description-into">
          <div className="card-video-description">

            <video className="video-description" controls poster="https://res.cloudinary.com/dizg4ktfd/image/upload/v1710626890/fisel_miniatura.png">
              <source src={videoUrl} type="video/mp4" />
            </video>
          </div>
          <div className="back-card1-text">
            <p className="card1-text-description">
              Fisel ofrece soluciones de financiamiento para empresas y
              emprendedores, facilitando el acceso al capital para expandir
              negocios o iniciar proyectos. Nuestro enfoque flexible y adaptado
              a las necesidades de cada cliente está diseñado para apoyar el
              crecimiento empresarial.
            </p>
          </div>
        </div>
      </div>
      <div className="flecha-description-back">
        <svg xmlns="http://www.w3.org/2000/svg" width="256" height="175" viewBox="0 0 256 175" fill="none">
        <path d="M215.636 0.893362C214.472 -0.286264 212.573 -0.299366 211.393 0.864098C210.214 2.02756 210.201 3.92701 211.364 5.10664L215.636 0.893362ZM222.5 167L223.479 169.836L222.5 167ZM2.00609 163.191C2.11163 164.845 3.53761 166.099 5.1911 165.994L32.1363 164.274C33.7898 164.168 35.0446 162.742 34.9391 161.089C34.8335 159.436 33.4076 158.181 31.7541 158.286L7.80281 159.815L6.274 135.864C6.16846 134.21 4.74248 132.955 3.089 133.061C1.43551 133.166 0.180647 134.592 0.286189 136.246L2.00609 163.191ZM211.364 5.10664C228.754 22.7384 243.807 58.5523 247.997 92.3336C250.086 109.177 249.431 125.21 245.215 138.005C241.023 150.729 233.397 160.064 221.521 164.164L223.479 169.836C237.569 164.971 246.3 153.887 250.914 139.883C255.504 125.95 256.103 108.948 253.951 91.595C249.658 56.9835 234.246 19.7616 215.636 0.893362L211.364 5.10664ZM221.521 164.164C201.308 171.143 182.719 169.167 164.819 163.447C146.726 157.665 129.793 148.25 112.366 140.331C95.166 132.516 77.6581 126.27 59.4058 127.8C41.0003 129.342 22.3635 138.728 2.74786 161.018L7.25214 164.982C26.1365 143.522 43.4372 135.158 59.9067 133.779C76.5294 132.386 92.834 138.047 109.884 145.794C126.707 153.438 144.461 163.241 162.993 169.162C181.719 175.146 201.692 177.357 223.479 169.836L221.521 164.164Z" fill="#0F0F0F"/>
        </svg>
      </div>

      <div className="card2-descriptio-container">
        <div className="card2-description">
          <h3 className="h3-description">¿Que problema resolvemos?</h3>
          <div className="card-description-into2">
            <div>
              <p className="card2-text-description">
                En Fisel entendemos las dificultades que enfrentan las empresas
                y emprendedores al tratar de obtener financiamiento. Por eso,
                ofrecemos soluciones flexibles y adaptadas a las necesidades de
                cada cliente, brindando una alternativa para aquellos que buscan
                expandir sus negocios o iniciar nuevos proyectos.
              </p>
            </div>
            {/* <div>
              <img className="image-row" src={Flecha}></img>
            </div> */}
          </div>
        </div>

        <div className="image-camera-container">
          <img className="image-camera" src={Camara}></img>
        </div>
      </div>

      <div className="card-description5">
        <h3 className="h3-description">¿Por qué elegirnos?</h3>
        <h2 className="h2-why">Por que...</h2>
        <div className="back-card-description2">
          {descriptions.map((description, index) => (
            <DescriptionCards
              key={index * 3 + "3"}
              indexImage={index}
              description={description.text}
            />
          ))}
        </div>
      <div className="back-card-description2bi">
      {filteredDescriptions1.map((description, index) => (
        <DescriptionCards1
          key={index}
          indexImage={index}
          description={description.text}
        />
      ))}
    </div>
    <div className="back-card-description2bi">
      {filteredDescriptions2.map((description, index) => (
        <DescriptionCards
          key={index}
          indexImage={1}
          description={description.text}
        />
      ))}
    </div>
    <div className="back-card-description2bi">
      {filteredDescriptions3.map((description, index) => (
        <DescriptionCards2
          key={index}
          indexImage={index}
          description={description.text}
        />
      ))}
    </div>
      </div>

      <Slider {...settings} className="carrousel-description">
      {descriptions.map((description, index) => (
            <DescriptionCards
              key={index * 3 + "3"}
              indexImage={index}
              description={description.text} 
              className="carrousel-description-card"
            />
          ))}
    </Slider>
    </div>
  );
};

export default Description;
