import React from "react";
import Aliado1 from "../../assets/alies/Aliado-1.png";
import Aliado2 from "../../assets/alies/Aliado-2.png";
import BackAllies from "../../assets/back-allies.png";
import "../../styles/others/Allies.css";

const Allies = () => {
  return (
    <div className="back-allies">
        <div className='back-partner-background'>
            <img className="img-allies" src={BackAllies}/>
        </div>
      <div className="back-allies-title">
        <h1 className="allies-title">Aliados Corporativos</h1>
      </div>
      <div className="back-allies-image">
        <div className="allies-card-image">
          <img className="allies-img" src={Aliado1} />
        </div>
        <div className="allies-card-image">
          <img className="allies-img" src={Aliado2} />
        </div>
      </div>
    </div>
  );
};

export default Allies;
