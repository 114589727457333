import React, { useState, useEffect } from "react";
import Navbar from "../components/common/navbar/Navbar";
import "../styles/pages/Scheduls.css";
import "react-calendar/dist/Calendar.css";
import { CardScheduls } from "../components/scheduls/CardScheduls";
import { Footer } from "../components/home/Footer";
import { useDispatch, useSelector } from "react-redux";
import { allDiary } from "../redux/thunks/diaryFetchThunk";
import BounceLoader from "react-spinners/BounceLoader";
import {
  Button,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import SortOutlinedIcon from "@mui/icons-material/SortOutlined";
import HelmetDetail from "../utils/HelmetDetail";

export const Scheduls = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      dispatch(allDiary());
    };
    const twoSecondsInMilliseconds = 2000;
    const timer = setTimeout(() => {
      fetchData();
    }, twoSecondsInMilliseconds);

    return () => clearTimeout(timer);
  }, [dispatch]);

  const allDiaryState = useSelector((state) => state.diary.allDiary);
  const isLoading = useSelector((state) => state.diary.isLoading);
  const [filterType, setFilterType] = useState("todo");
  const [searchTerm, setSearchTerm] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleClose = () => {
    setOpenDrawer(false);
  };

  const handleTypeChange = (value) => {
    setFilterType(value);
  };

  const normalizedSearchTerm = searchTerm
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");

  const filteredDiaries = Array.isArray(allDiaryState)
    ? allDiaryState
        .filter((e) => (filterType === "todo" ? e : e.type === filterType))
        .filter((diary) => {
          if (diary && diary.title) {
            const normalizedTitle = diary.title
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "");
            return normalizedTitle.includes(normalizedSearchTerm);
          }
          return false;
        })
    : [].reverse();

  const countries = [
    "Argentina",
    "Brasil",
    "Chile",
    "México",
    "Perú",
    "España",
    "EE. UU.",
  ];

  if (isLoading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100vh",
        }}
      >
        <BounceLoader color="#0e2b60" />
      </div>
    );

  return (
    <div>
      <HelmetDetail
        tabTitle="Fisel | Agenda"
        title="Agenda de los ultimos eventos y cursos - FISEL"
        description="Aprende nuevas habilidades o participa de los eventos - FISEL"
        type="website"
      />
      <Navbar />
      <div className="calendar-container">
        <div className="calendar">
          <h1 className="title-calendar">Agenda</h1>
          <p className="sub-calendar">Proximos eventos</p>

          <div className="back-input-calendar-into">
            <div className="back-input-calendar">
              <div>
                <button
                  className="schedul-filter-button"
                  onClick={() => setOpenDrawer(true)}
                >
                  <span>FILTROS</span>
                  <SortOutlinedIcon style={{ fontSize: "17px" }} />
                </button>
              </div>
              <div style={{ width: "100%" }}>
                <input
                  type="text"
                  placeholder="Buscar titulo"
                  value={searchTerm}
                  className="input-calendar"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>

            <div className="column-row-agenda">
              {filteredDiaries.map((diary, index) => (
                <CardScheduls key={index} diary={diary} />
              ))}
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <Drawer open={openDrawer} onClose={handleClose}>
        <FilterDrawer onClose={handleClose} onChange={handleTypeChange} />
      </Drawer>
    </div>
  );
};

const FilterDrawer = ({ onClose, onChange }) => {
  const [type, setType] = useState("todo");

  const handleType = (event) => {
    const value = event.target.value;
    setType(value);
    onChange(value);
  };

  return (
    <div className="drawer-schedul-filter">
      <div className="drawer-schedul-header">
        <h2>filtros de agenda</h2>
        <Button variant="outlined" onClick={onClose}>
          x
        </Button>
      </div>
      <div>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="drawerType">Tipo de agenda</InputLabel>
          <Select
            labelId="drawerType"
            label="Tipo de agenda"
            value={type}
            onChange={handleType}
          >
            <MenuItem value="todo">Todo</MenuItem>
            <MenuItem value="curso">Cursos</MenuItem>
            <MenuItem value="evento">Eventos</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
};
