import { Alert, Button, CircularProgress, Typography } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import ModalUserDelete from "../../../common/modals/ModalUserDelete";
import logout from "../../../../utils/logout";

const DangerZone = ({ user, isLoading }) => {
  const [userDelete, setUserDelete] = useState(null);

  if (isLoading) return <CircularProgress />;

  const handleLogout = () => {
    logout();
  };

  return (
    <Component>
      <Typography className="title">Eliminar Cuenta</Typography>
      <Alert variant="outlined" severity="error" className="alert">
        Al eliminar la cuenta, se eliminarán permanentemente todos los datos
        almacenados del usuario como noticias creadas y sus datos personales.
        Esto no se puede deshacer.
      </Alert>
      <Button
        variant="contained"
        color="error"
        onClick={() => setUserDelete(user)}
      >
        Eliminar Cuenta
      </Button>

      <ModalUserDelete
        user={userDelete}
        url="auth"
        onClose={() => setUserDelete(null)}
        onFinish={handleLogout}
      />
    </Component>
  );
};

const Component = styled.div`
  .title {
    font-size: 18px;
    color: gray;
  }

  .alert {
    margin: 20px 0px;
  }
`;

export default DangerZone;
