import React from "react";
import { Helmet } from "react-helmet";

const HelmetDetail = ({ tabTitle, title, description, image, type }) => {
  const url = window.location.href;

  const baseImage =
    "https://res.cloudinary.com/dk7wevchr/image/upload/v1722048949/rog1aow44pslzyo4ppwh.png";

  return (
    <div>
      <Helmet>
        {/* <!-- Primary Meta Tags --> */}
        <title>{tabTitle}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content={type} />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image | baseImage} />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={url} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={image} />
      </Helmet>
    </div>
  );
};

export default HelmetDetail;
